<template>
  <div>
    <div class="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
      <!-- Vertical Navbar -->     
      <nav
        class="
          navbar
          show
          navbar-vertical
          h-lg-screen
          navbar-expand-lg
          px-0
          py-3
          navbar-light
          bg-white
          border-bottom border-bottom-lg-0 border-end-lg
        "
        id="navbarVertical"
      >
        <div class="container-fluid">
          <!-- Toggler -->
          <button
            class="navbar-toggler ms-n2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#sidebarCollapse"
            aria-controls="sidebarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <!-- Brand -->
          <a
            class="navbar-brand py-lg-2 mb-lg-5 px-lg-6 me-0 ml-4"
            role="button"
            @click="showDiv('dashboard')"
          >
            <img src="@/assets/inkpptLogo.svg" alt="..." />
          </a>
          <!-- User menu (mobile) -->
          <div class="navbar-user d-lg-none">
            <!-- Dropdown -->
            <div class="dropdown">
              <!-- Toggle -->
              <a
                href="#"
                id="sidebarAvatar"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div class="avatar-parent-child">
                  <img
                    alt="Image Placeholder"
                    src="https://images.unsplash.com/photo-1548142813-c348350df52b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80"
                    class="avatar avatar- rounded-circle"
                  />
                  <span class="avatar-child avatar-badge bg-success"></span>
                </div>
              </a>
              <!-- Menu -->
              <div
                class="dropdown-menu dropdown-menu-end"
                aria-labelledby="sidebarAvatar"
              >
                <a href="#" class="dropdown-item">Profile</a>
                <a href="#" class="dropdown-item">Settings</a>
                <a href="#" class="dropdown-item">Billing</a>
                <hr class="dropdown-divider" />
                <a href="#" class="dropdown-item">Logout</a>
              </div>
            </div>
          </div>
          <!-- Collapse -->
          <div class="collapse navbar-collapse" id="sidebarCollapse">
            <!-- Navigation -->
            <ul class="navbar-nav">
              <li class="nav-item">
                <a
                  :class="{ active: show == 'dashboard' }"
                  class="nav-link"
                  @click="showDiv('dashboard')"
                  href="#"
                >
                  <i class="bi bi-house"></i> Dashboard
                </a>
              </li>

              <li class="nav-item">
                <a
                  :class="{ active: show == 'users' }"
                  class="nav-link"
                  @click="showDiv('users')"
                  href="#"
                >
                  <i class="bi bi-person-square"></i>Registered Users</a
                >
              </li>
              <li class="nav-item">
                <a
                  :class="{ active: show == 'backendusers' }"
                  class="nav-link"
                  @click="showDiv('backendusers')"
                  href="#"
                >
                  <i class="bi bi-person-square"></i>Manage Admins
                </a>
              </li>
              <li class="nav-item">
                <a
                  :class="{ active: show == 'parentbuckets' }"
                  class="nav-link"
                  @click="showDiv('parentbuckets')"
                  href="#"
                >
                  <i class="bi bi-person-square"></i>Master Buckets
                </a>
              </li>
              <li class="nav-item">
                <a
                  :class="{ active: show == 'childbuckets' }"
                  class="nav-link"
                  @click="showDiv('childbuckets')"
                  href="#"
                >
                  <i class="bi bi-person-square"></i>Child Buckets
                </a>
              </li>
              <li class="nav-item">
                <a
                  :class="{ active: show == 'documentation' }"
                  class="nav-link"
                  @click="showDiv('documentation')"
                  href="#"
                >
                  <i class="bi bi-bar-chart"></i> Downloads
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" @click="showDiv('notification')" href="#">
                  <i class="bi bi-chat"></i> Notification
                  <span
                    class="
                      badge
                      bg-soft-primary
                      text-primary
                      rounded-pill
                      d-inline-flex
                      align-items-center
                      ms-auto
                    "
                    >6</span
                  >
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#" @click="logout">
                  <i class="bi bi-box-arrow-left"></i> Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <!-- Main content -->
      <div class="h-screen flex-grow-1 overflow-y-lg-auto">
        <!-- Header -->
        <header class="bg-surface-primary border-bottom pt-6">
          <div class="container-fluid">
            <div class="mb-npx">
              <div class="row align-items-center">
                <div class="col-sm-6 col-12 mb-4 mb-sm-0">
                  <!-- Title -->
                  <h1 class="h2 mb-0 ls-tight">Admin Pannel</h1>
                </div>
                <!-- Actions -->
              </div>
            </div>
          </div>
        </header>
        <!-- Main -->

        <div v-if="show == 'dashboard'">
          <main class="py-6 bg-surface-secondary">
            <div class="container-fluid">
              <div class="card-header">
                <h5 class="mb-0">My DashBoard</h5>
              </div>
              <!-- Card stats -->
              <div class="row g-6 mb-6">
                <div class="col-xl-3 col-sm-6 col-12">
                  <div class="card shadow border-0">
                    <div class="card-body">
                      <div class="row">
                        <div class="col">
                          <span
                            class="
                              h6
                              font-semibold
                              text-muted text-sm
                              d-block
                              mb-2
                            "
                            >Plan Details</span
                          >
                          <span class="h3 font-bold mb-0">Basic</span>
                        </div>
                        <div class="col-auto">
                          <div
                            class="
                              icon icon-shape
                              bg-tertiary
                              text-white text-lg
                              rounded-circle
                            "
                          >
                            <i class="bi bi-credit-card"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-sm-6 col-12">
                  <div class="card shadow border-0">
                    <div class="card-body">
                      <div class="row">
                        <div class="col">
                          <span
                            class="
                              h6
                              font-semibold
                              text-muted text-sm
                              d-block
                              mb-2
                            "
                            >Total Users</span
                          >
                          <span class="h3 font-bold mb-0">{{
                            AllRegisterUser.length
                          }}</span>
                        </div>
                        <div class="col-auto">
                          <div
                            class="
                              icon icon-shape
                              bg-primary
                              text-white text-lg
                              rounded-circle
                            "
                          >
                            <i class="bi bi-people"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                  <div class="col-xl-3 col-sm-6 col-12">
                  <div class="card shadow border-0">
                    <div class="card-body">
                      <div class="row">
                        <div class="col">
                          <span
                            class="
                              h6
                              font-semibold
                              text-muted text-sm
                              d-block
                              mb-2
                            "
                            >Total Admins</span
                          >
                          <span class="h3 font-bold mb-0">{{
                            AllRegisterMember.length
                          }}</span>
                        </div>
                        <div class="col-auto">
                          <div
                            class="
                              icon icon-shape
                              bg-primary
                              text-white text-lg
                              rounded-circle
                            "
                          >
                            <i class="bi bi-people"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-sm-6 col-12">
                  <div class="card shadow border-0">
                    <div class="card-body">
                      <div class="row">
                        <div class="col">
                          <span
                            class="
                              h6
                              font-semibold
                              text-muted text-sm
                              d-block
                              mb-2
                            "
                            >Valid Till</span
                          >
                          <span class="h3 font-bold mb-0">{{TenantInfo[0].lastName}}</span>
                        </div>
                        <div class="col-auto">
                          <div
                            class="
                              icon icon-shape
                              bg-info
                              text-white text-lg
                              rounded-circle
                            "
                          >
                            <i class="bi bi-clock-history"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-sm-6 col-12">
                  <div class="card shadow border-0">
                    <div class="card-body">
                      <div class="row">
                        <div class="col">
                          <span
                            class="
                              h6
                              font-semibold
                              text-muted text-sm
                              d-block
                              mb-2
                            "
                            >Total Master Bucket</span
                          >
                          <span class="h3 font-bold mb-0">{{
                            BucketData.AllAssets.length
                          }}</span>
                        </div>
                        <div class="col-auto">
                          <div
                            class="
                              icon icon-shape
                              bg-warning
                              text-white text-lg
                              rounded-circle
                            "
                          >
                            <i class="bi bi-minecart-loaded"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-sm-6 col-12">
                  <div class="card shadow border-0">
                    <div class="card-body">
                      <div class="row">
                        <div class="col">
                          <span
                            class="
                              h6
                              font-semibold
                              text-muted text-sm
                              d-block
                              mb-2
                            "
                            >Total Child Bucket</span
                          >
                          <span class="h3 font-bold mb-0">{{
                            BucketData.AllBuckets.length
                          }}</span>
                        </div>
                        <div class="col-auto">
                          <div
                            class="
                              icon icon-shape
                              bg-warning
                              text-white text-lg
                              rounded-circle
                            "
                          >
                            <i class="bi bi-minecart-loaded"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row g-6 mb-6">
                
              </div>
            </div>
          </main>
        </div>
        <div v-if="show == 'documentation'">
          <main class="py-6 bg-surface-secondary">
            <div class="card-header">
              <h5 class="mb-0">Documentation</h5>
            </div>
            <div class="card shadow border-0 mb-7">
              <div class="table-responsive">
                <table class="table table-hover table-nowrap">
                  <tbody>
                    <tr>
                      <td>
                        <a  href=""  target="_black" download>
                          Download Procedure Pdf</a
                        >
                      </td>
                      <td class="text-end">
                        <a  href='' @click="clickedDownload">Download</a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a class="text-heading font-semibold" href="#">
                          Download Admin App
                        </a>
                      </td>
                      <td class="text-end">
                        <a href="#" class="btn btn-sm btn-neutral">Download</a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a class="text-heading font-semibold" href="#">
                          Download Assets Sample
                        </a>
                      </td>
                      <td class="text-end">
                        <a href="#" class="btn btn-sm btn-neutral">Download</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </main>
        </div>
        <div v-if="show == 'users'">
          <main class="py-6 bg-surface-secondary">
            <div class="card-header">
              <h5 class="mb-0">Manage Users</h5>
            </div>
            <button class="btn btn-sm btn-neutral" @click="userModal = true">
              Add New User
            </button>
            <transition name="modal" v-if="userModal">
              <div class="modal-mask">
                <div class="modal-wrapper">
                  <div class="modal-container">
                    <div class="d-flex justify-content-center">
                      <div class="card px-5 pt-8 pb-0 mt-3 mb-3 signup-wizard">
                        <h2 class="d-flex justify-content-center">
                          <strong style="color: rgb(76, 21, 106)"
                            >Add New User</strong
                          >
                        </h2>

                        <div class="row">
                          <div class="col-md-12 mx-0">
                            <form id="msform">
                              <!-- progressbar -->

                              <fieldset>
                                <div class="form-card">
                                  <input
                                    v-model="registerUser.firstName"
                                    type="text"
                                    name="fname"
                                    placeholder="FirstName"
                                  />
                                  <input
                                    v-model="registerUser.lastName"
                                    type="text"
                                    name="lname"
                                    placeholder="LastName"
                                  />
                                  <input
                                    v-model="registerUser.email"
                                    type="email"
                                    name="email"
                                    placeholder="Email Id"
                                 
                                  />
                                  <input
                                    v-model="registerUser.password"
                                    type=""
                                    name="pwd"
                                    placeholder="Password"
                                  />
                                </div>
                                <a
                                  v-show="buttonText == 'Add'"
                                  href="#"
                                  class="btn btn-sm btn-neutral"
                                  @click="register"
                                  >Add</a
                                >
                                <a
                                  v-show="buttonText == 'Update'"
                                  href="#"
                                  class="btn btn-sm btn-neutral"
                                  @click="update(registerUser.userId)"
                                  >Update</a
                                >
                                <a
                                  style="margin-left: 10px"
                                  href="#"
                                  class="btn btn-sm btn-neutral"
                                  @click="cancel()"
                                  >Cancel</a
                                >
                                <!-- <input type="button" name="next" class="next action-button" @click="register" value="Add" /> -->
                              </fieldset>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <div class="card shadow border-0 mb-7" id="userForDiv">
              <div class="d-flex justify-content-center">
                <table class="table table-hover table-nowrap">
                  <div v-if="pageLoadingUsers === true" id="preloader_id">
                    <Preloader />
                  </div>
                  <thead class="thead-light">
                    <tr>
                      <th scope="col">Name</th>
                      <!-- <th scope="col">Date</th>
                                        <th scope="col">Company</th>
                                        <th scope="col">Offer</th> -->
                      <th scope="col">Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(users, index) in AllRegisterUser"
                      v-bind:key="index"
                    >
                      <td>
                        <!-- <img
                          alt="..."
                          src="https://images.unsplash.com/photo-1502823403499-6ccfcf4fb453?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80"
                          class="avatar avatar-sm rounded-circle me-2"
                        /> -->
                        <a class="text-heading font-semibold" href="#">
                          {{ users.firstName + users.lastName }}
                        </a>
                      </td>
                      <!-- <td> Feb 15, 2021 </td>
                                        <td> <img alt="..." src="https://preview.webpixels.io/web/img/other/logos/logo-1.png" class="avatar avatar-xs rounded-circle me-2"> <a class="text-heading font-semibold" href="#"> Dribbble </a> </td>
                                        <td> $3.500 </td> -->
                      <td>
                        <span class="badge badge-lg badge-dot">
                          <i class="bg-success"></i>Active
                        </span>
                      </td>
                      <td class="text-end">
                        <a
                          href="#"
                          class="btn btn-sm btn-neutral"
                          @click="editUser(users.userId)"
                          >Edit</a
                        >
                        <button type="button"
                          @click="deleteUser(users.userId)"
                          class="btn btn-sm btn-square btn-neutral text-danger-hover">
                          <i class="bi bi-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </main>
        </div>
        <div v-if="show == 'backendusers'">
          <main class="py-6 bg-surface-secondary">
            <div class="card-header">
              <h5 class="mb-0">Manage Admins</h5>
            </div>
            <button class="btn btn-sm btn-neutral" @click="backendUserModal = true">
              Add New Admin
            </button>
            <transition name="modal" v-if="backendUserModal">
              <div class="modal-mask">
                <div class="modal-wrapper">
                  <div class="modal-container">
                    <div class="d-flex justify-content-center">
                      <div class="card px-5 pt-8 pb-0 mt-3 mb-3 signup-wizard">
                        <h2 class="d-flex justify-content-center">
                          <strong style="color: rgb(76, 21, 106)"
                            >Add New Admin</strong
                          >
                        </h2>

                        <div class="row">
                          <div class="col-md-12 mx-0">
                            <form id="msform">
                              <!-- progressbar -->

                              <fieldset>
                                <div class="form-card">
                                  <input
                                    v-model="registerMember.username"
                                    type="text"
                                    name="username"
                                    placeholder="UserName"
                                       @input="checkUsername"
                                  />
                                   <span class="text-danger">
                                       {{ registerMember.emailErrMsg }}
                                         </span>
                                
                                     <select                                   
                                    style="width: 300px"
                                    v-model="registerMember.role"
                                    class="form-select form-select-lg mb-3"
                                    aria-label=".form-select-lg example"
                                  >
                                    <option value="Admin">Admin</option>
                                    <option value="Normal">Normal</option>
                                  </select>
                                  <input
                                    v-model="registerMember.password"
                                    type=""
                                    name="pwd"
                                    placeholder="Password"
                                  />
                                </div>
                                <a
                                  v-show="buttonText == 'Add'"
                                  href="#"
                                  class="btn btn-sm btn-neutral"
                                  @click="registerMembers()"
                                  >Add</a
                                >
                                <a
                                  v-show="buttonText == 'Update'"
                                  href="#"
                                  class="btn btn-sm btn-neutral"
                                  @click="updateMember(registerMember.userId)"
                                  >Update</a
                                >
                                <a
                                  style="margin-left: 10px"
                                  href="#"
                                  class="btn btn-sm btn-neutral"
                                  @click="cancel()"
                                  >Cancel</a
                                >
                                <!-- <input type="button" name="next" class="next action-button" @click="register" value="Add" /> -->
                              </fieldset>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <div class="card shadow border-0 mb-7" id="userForDiv">
              <div class="d-flex justify-content-center">
                <table class="table table-hover table-nowrap">
                  <div v-if="pageLoadingMembers === true" id="preloader_id">
                    <Preloader />
                  </div>
                  <thead class="thead-light">
                    <tr>
                      <th scope="col">Name</th>
                      <!-- <th scope="col">Date</th>
                                        <th scope="col">Company</th>
                                        <th scope="col">Offer</th> -->
                      <th scope="col">Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(backendusers, index) in AllRegisterMember"
                      v-bind:key="index"
                    >
                      <td>
                        <!-- <img
                          alt="..."
                          src="https://images.unsplash.com/photo-1502823403499-6ccfcf4fb453?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80"
                          class="avatar avatar-sm rounded-circle me-2"
                        /> -->
                        <a class="text-heading font-semibold" href="#">
                          {{ backendusers.firstName }}
                        </a>
                      </td>
                      <!-- <td> Feb 15, 2021 </td>
                                        <td> <img alt="..." src="https://preview.webpixels.io/web/img/other/logos/logo-1.png" class="avatar avatar-xs rounded-circle me-2"> <a class="text-heading font-semibold" href="#"> Dribbble </a> </td>
                                        <td> $3.500 </td> -->
                      <td>
                        <span class="badge badge-lg badge-dot">
                          <i class="bg-success"></i>Active
                        </span>
                      </td>
                      <td class="text-end">
                        <a
                          href="#"
                          class="btn btn-sm btn-neutral"
                          @click="editMember(backendusers.userId)"
                          >Edit</a
                        >
                        <button type="button"
                          @click="deleteMember(backendusers.userId)"
                          class="btn btn-sm btn-square btn-neutral text-danger-hover">
                          <i class="bi bi-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </main>
        </div>
        <div v-if="show == 'parentbuckets'" id="parentFomrDiv">
          <main class="py-6 bg-surface-secondary">
            <div class="card-header">
              <h5 class="mb-0">Manage Master Buckets</h5>
            </div>
            <button
              class="btn btn-sm btn-neutral" @click="masterBucketModal = true">
              Add New Bucket
            </button>

            <transition name="modal" v-if="masterBucketModal">
              <div class="modal-mask">
                <div class="modal-wrapper">
                  <div class="modal-container">
                    <div class="d-flex justify-content-center">
                      <div class="card px-5 pt-8 pb-0 mt-3 mb-3 signup-wizard">
                        <h2 class="d-flex justify-content-center">
                          <strong style="color: rgb(76, 21, 106)"
                            >Add New Bucket</strong
                          >
                        </h2>

                        <div class="row">
                          <div class="col-md-12 mx-0">
                            <form id="msform">
                              <!-- progressbar -->

                              <fieldset>
                                <div class="form-card" id="parentFormDiv">
                                  <input
                                    v-model="parentBucketForm.bucketName"
                                    type="text"
                                    name="fname"
                                    placeholder="Bucket Name"
                                  />
                                    <select
                                    v-show="buttonText == 'Update'"
                                    style="width: 300px"
                                    v-model="parentBucketForm.bucketStatus"
                                    class="form-select form-select-lg mb-3"
                                    aria-label=".form-select-lg example"
                                  >
                                    <option :value="1">Active</option>
                                    <option :value="0">InActive</option>
                                  </select>
                                  <input
                                    type="file"
                                    name=""
                                    @change="onChangeFile"
                                    multiple
                                  />

                                
                                </div>
                                <a
                                  v-show="buttonText == 'Add'"
                                  href="#"
                                  class="btn btn-sm btn-neutral"
                                  @click="addParentBucket"
                                  >Add</a
                                >
                                <a
                                  v-show="buttonText == 'Update'"
                                  href="#"
                                  class="btn btn-sm btn-neutral"
                                  @click="
                                    updateParentBucket(childbucketForm.bucketId)
                                  "
                                  >Update</a
                                >
                                <a
                                  style="margin-left: 10px"
                                  href="#"
                                  class="btn btn-sm btn-neutral"
                                  @click="cancel()"
                                  >Cancel</a
                                >
                                <!-- <input type="button" name="next" class="next action-button" @click="register" value="Add" /> -->
                              </fieldset>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <div class="card shadow border-0 mb-7">
              <div class="d-flex justify-content-center">
                <table class="table table-hover table-nowrap">
                  <div v-if="pageLoadingBuckets === true" id="preloader_id">
                    <Preloader />
                  </div>
                  <thead class="thead-light">
                    <tr>
                      <th scope="col">Name</th>
                      <!-- <th scope="col">Date</th>
                                        <th scope="col">Company</th>
                                        <th scope="col">Offer</th> -->
                      <th scope="col">Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(bucket, index) in BucketData.AllAssets"
                      v-bind:key="index"
                    >
                      <td>
                        <img
                          alt="..."
                          :src="baseurlIcons + bucket.assetLogo"
                          class="avatar avatar-sm rounded-circle me-2"
                        />
                        <a class="text-heading font-semibold" href="#">
                          {{ bucket.assetName }}
                        </a>
                      </td>
                      <!-- <td> Feb 15, 2021 </td>
                                        <td> <img alt="..." src="https://preview.webpixels.io/web/img/other/logos/logo-1.png" class="avatar avatar-xs rounded-circle me-2"> <a class="text-heading font-semibold" href="#"> Dribbble </a> </td>
                                        <td> $3.500 </td> -->
                      <td>
                        <span
                          v-show="bucket.bucketStatus == '1'"
                          class="badge badge-lg badge-dot"
                        >
                          <i class="bg-success"></i>Active
                        </span>
                        <span
                          v-show="bucket.bucketStatus == '0'"
                          class="badge badge-lg badge-dot"
                        >
                          <i class="bg-danger"></i>InActive
                        </span>
                      </td>
                      <td class="text-end">
                        <a
                          href="#"
                          class="btn btn-sm btn-neutral"
                          @click="editParentBucket(bucket.assetId)"
                          >Edit</a
                        >
                        <button
                          type="button"
                          @click="deleteBucket(bucket.assetId)"
                          class="
                            btn btn-sm btn-square btn-neutral
                            text-danger-hover
                          "
                        >
                          <i class="bi bi-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </main>
        </div>
        <div v-if="show == 'childbuckets'">
          <button
            class="btn btn-sm btn-neutral"
            @click="childBucketModal = true"
          >
            Add New Bucket
          </button>

          <transition name="modal" v-if="childBucketModal">
            <div class="modal-mask">
              <div class="modal-wrapper">
                <div class="modal-container">
                  <div class="d-flex justify-content-center">
                    <div class="card px-5 pt-8 pb-0 mt-3 mb-3 signup-wizard">
                      <h2 class="d-flex justify-content-center">
                        <strong style="color: rgb(76, 21, 106)"
                          >Add Child Bucket</strong
                        >
                      </h2>

                      <div class="row">
                        <div class="col-md-12 mx-0">
                          <form id="msform">
                            <!-- progressbar -->

                            <fieldset>
                              <div class="form-card">
                                <select
                                  style="width: 300px"
                                  v-model="childbucketForm.parentSelectedValue"
                                  class="form-select form-select-lg mb-3"
                                  aria-label=".form-select-lg example"
                                >
                                  <option value="">Select Master Bucket</option>
                                  <option
                                    v-for="bucket in BucketData.AllAssets"
                                    :value="bucket.assetId"
                                    v-bind:key="bucket"
                                  >
                                    {{ bucket.assetName }}
                                  </option>
                                </select>
                                <input
                                  v-model="childbucketForm.bucketName"
                                  type="text"
                                  name="fname"
                                  placeholder="Bucket Name"
                                />

                                <select
                                  style="width: 300px"
                                  v-model="childbucketForm.assetTypeSelected"
                                  class="form-select form-select-lg mb-3"
                                  aria-label=".form-select-lg example"
                                >
                                  <option value="">Select Asset Type</option>
                                  <option :value="1">Icons</option>
                                  <option :value="3">Images</option>
                                  <option :value="4">Gifs</option>
                                  <option :value="5">Smart Arts</option>
                                  <option :value="6">Charts</option>
                                  <option :value="8">Slide</option>
                                </select>
                                <select
                                  style="width: 300px"
                                  v-model="childbucketForm.viewStyleSelected"
                                  class="form-select form-select-lg mb-3"
                                  aria-label=".form-select-lg example"
                                >
                                  <option value="">Select View Style</option>
                                  <option :value="1">
                                    One Cloumn(Rectangle)
                                  </option>
                                  <option :value="2">One Column(Square)</option>
                                  <option :value="3">Two column</option>
                                  <option :value="4">Three Column</option>
                                  <option :value="9">Four Column</option>
                                </select>

                                <select
                                  v-show="buttonText == 'Update'"
                                  style="width: 300px"
                                  v-model="childbucketForm.bucketStatus"
                                  class="form-select form-select-lg mb-3"
                                  aria-label=".form-select-lg example"
                                >
                                  <option :value="1">Active</option>
                                  <option :value="0">InActive</option>
                                </select>
                                  <input
                                  type="file"
                                  name=""
                                  @change="onChangeFile"                                  
                                />
                              </div>
                              <a
                                v-show="buttonText == 'Add'"
                                href="#"
                                class="btn btn-sm btn-neutral"
                                @click="addChildBucket"
                                >Add</a
                              >
                              <a
                                v-show="buttonText == 'Update'"
                                href="#"
                                class="btn btn-sm btn-neutral"
                                @click="
                                  updateChildBucket(childbucketForm.bucketId)
                                "
                                >Update</a
                              >
                              <a
                                style="margin-left: 10px"
                                href="#"
                                class="btn btn-sm btn-neutral"
                                @click="cancel()"
                                >Cancel</a
                              >
                              <!-- <input type="button" name="next" class="next action-button" @click="register" value="Add" /> -->
                            </fieldset>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
          <main class="py-6 bg-surface-secondary">
            <div class="card-header">
              <h5 class="mb-0">Manage Child Buckets</h5>
            </div>
            <div class="card shadow border-0 mb-7">
              <select
                style="width: 250px"
                v-model="selectedParentValue"
                class="form-select form-select-lg mb-3"
                aria-label=".form-select-lg example"
              >
                <option value="">Select Master Bucket</option>
                <option
                  v-for="bucket in BucketData.AllAssets"
                  :value="bucket.assetId"
                  v-bind:key="bucket"
                >
                  {{ bucket.assetName }}
                </option>
              </select>
              <div class="d-flex justify-content-center">
                <table class="table table-hover table-nowrap">
                  <div v-if="pageLoadingBuckets === true" id="preloader_id">
                    <Preloader />
                  </div>
                  <thead class="thead-light">
                    <tr>
                      <th scope="col">Name</th>
                      <!-- <th scope="col">Date</th>
                                        <th scope="col">Company</th>
                                        <th scope="col">Offer</th> -->
                      <th scope="col">Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(bucket, index) in BucketData.AllBuckets"
                      v-bind:key="index"
                      v-show="bucket.parentId == selectedParentValue"
                    >
                      <td>
                        <img
                          alt="..."
                          :src="baseurlIcons + bucket.assetLogo"
                          class="avatar avatar-sm rounded-circle me-2"
                        />
                        <a class="text-heading font-semibold" href="#">
                          {{ bucket.assetName }}
                        </a>
                      </td>
                      <!-- <td> Feb 15, 2021 </td>
                                        <td> <img alt="..." src="https://preview.webpixels.io/web/img/other/logos/logo-1.png" class="avatar avatar-xs rounded-circle me-2"> <a class="text-heading font-semibold" href="#"> Dribbble </a> </td>
                                        <td> $3.500 </td> -->
                      <td>
                        <span
                          v-show="bucket.bucketStatus == '1'"
                          class="badge badge-lg badge-dot"
                        >
                          <i class="bg-success"></i>Active
                        </span>
                        <span
                          v-show="bucket.bucketStatus == '0'"
                          class="badge badge-lg badge-dot"
                        >
                          <i class="bg-danger"></i>InActive
                        </span>
                      </td>
                      <td class="text-end">
                        <a
                          href="#"
                          class="btn btn-sm btn-neutral"
                          @click="editChildBucket(bucket.assetId)"
                          >Edit</a
                        >
                        <button
                          type="button"
                          @click="deleteBucket(bucket.assetId)"
                          class="
                            btn btn-sm btn-square btn-neutral
                            text-danger-hover
                          "
                        >
                          <i class="bi bi-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Preloader from "./Preloader.vue";
import axios from "axios";
import common from "../common/index";
import AWSS3UploadAshClient from "aws-s3-upload-ash";
export default {
  name: "Dashboard",
  components: {
    Preloader
  },
  data() {
    return {
      BucketsMessage: null,
      childBucketModal: false,
      TenantInfo:[],
      masterBucketModal: false,
      userModal: false,
      backendUserModal: false,
      pageLoadingUsers: false,
      pageLoadingMembers: false,
      pageLoadingBuckets: false,
      show: "dashboard",
      BucketData: {
        currentAsset: "all",
        SlideBuckets: [],
        IconBuckets: [],
        GifBuckets: [],
        ImageBuckets: [],
        ChartBuckets: [],
        SmartArtBuckets: [],
        ThumbNailBuckets: [],
        AllAssets: [],
        AllBuckets: [],
        searching: false,
      },
      AllRegisterUser: [],
      AllRegisterMember: [],
      buttonText: "Add",
      registerUser: {
        userId: "",
        firstName: "",
        lastName: "",
        email: "",
        tenantId: "",
        password: "",
      },
      registerMember: {
        userNameErrMsg:"",
        Id: "",
        username: "",
        role: "",
        tenantId: "",
        password: "",
      },
      parentBucketForm: {
        logoChanged:false,
        formData: null,
        bucketName: "",
        tenantId: "",
        bucketStatus: "",
        bucketId: "",

        bucketLogoName: "",
        bucketLogo: null,
      },
      childbucketForm: {
        logoChanged:false,
        formData: null,
        bucketId: "",
        parentSelectedValue: "",
        bucketName: "",
        assetTypeSelected: "",
        viewStyleSelected: "",
        bucketLogo: null,
        bucketLogoName: "",
        tenantId: "",
        bucketStatus: "",
      },
      selectedParentValue: "",
      fileSelectedName:"",
       fileSelected: null,
      config: {
        bucketName: "inkppt-beta-frontend",
        dirName:
          "Images/Assets/Buckets" /* optional - when use: e.g BUCKET_ROOT/dirName/fileName.extesion */,
        region: "ap-south-1",
        accessKeyId: "AKIARVHHZRJPMHZ6P3OM",
        secretAccessKey: "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",
        s3Url: "https://inkppt-beta-frontend.s3.amazonaws.com/",        
      },
    };
  },
  methods: {
       onChangeFile: function (event) {
         
      this.fileSelected = event.target.files[0];
      this.fileSelectedName = btoa(Math.random().toString()).substr(12, 13) + this.fileSelected.name;
         let S3CustomClient = new AWSS3UploadAshClient(this.config);
      S3CustomClient.uploadFile(
        this.fileSelected,
        this.fileSelected.type,
        undefined,
        this.fileSelectedName,
        "public-read"
      )
        .then((data) => console.log(data))
        .catch((err) => console.error(err));
    },
     clickedDownload(e){
       e.preventDefault();
        const link = document.createElement('a');
      link.href = '../src/assets/sample.pdf';
      link.setAttribute('download', 'sample.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();
    }, 
    logout() {
      localStorage.removeItem("tenantId");
      this.$router.push("/");
    },
    cancel() {
      this.userModal = false;
      this.backendUserModal = false;
      this.masterBucketModal = false;
      this.childBucketModal = false;
      this.registerUser = {};
      this.registerMember = {};
      this.childbucketForm = {};
      this.parentBucketForm = {};
      this.buttonText = "Add";
    },
      checkUsername(){
         this.$store
          .dispatch("checkUserName", this.registerMember.username)
          .then((response) => {
            if (response === "mail already exists") {
              this.registerMember.userNameErrMsg = "UserName Already Exists";
            } else {
              this.registerMember.userNameErrMsg = "";
            }
          });
    },
    parentBucketLogo(event) {
     this.parentBucketForm.logoChanged = true;
      let formData = new FormData();
       formData.append(
        "bucketId",
         this.parentBucketForm.bucketId
      ); 
       formData.append(
        "bucketName",
        this.parentBucketForm.bucketName        
      ); 
      formData.append(
        "bucketLogo",
        event.target.files[0]        
      );
       formData.append(
         "bucketLogoName",
        event.target.files[0].name      
      );      
       formData.append(
         "bucketStatus",
       this.parentBucketForm.bucketStatus   
      );
       formData.append(
         "tenantId",
       localStorage.getItem("tenantId")    
      );
      this.parentBucketForm.formData = formData;
    
    },
    // childBucketLogo(event) {
    //   this.childbucketForm.logoChanged = true;
    //   let formData = new FormData();     
    //     formData.append(
    //     "parentSelectedValue",
    //      this.childbucketForm.parentSelectedValue
    //   ); 
    //     formData.append(
    //     "bucketId",
    //      this.childbucketForm.bucketId
    //   );   
    //    formData.append(
    //     "bucketName",
    //     this.childbucketForm.bucketName        
    //   ); 
    //     formData.append(
    //     "bucketStatus",
    //     this.childbucketForm.bucketStatus        
    //   ); 
    //     formData.append(
    //     "viewStyleSelected",
    //     this.childbucketForm.viewStyleSelected
    //   ); 
    //      formData.append(
    //     "assetTypeSelected",
    //     this.childbucketForm.assetTypeSelected
    //   );       
    //   formData.append(
    //     "bucketLogo",
    //     event.target.files[0]        
    //   );      
    //    formData.append(
    //      "tenantId",
    //    localStorage.getItem("tenantId")    
    //   );
    //   this.childbucketForm.formData = formData;
    // },
    showDiv(data) {
      this.show = data;
      this.buttonText = "Add";
    },
    deleteUser(id) {
      this.pageLoading = true;
      this.registerUser.tenantId = localStorage.getItem("tenantId");
      this.$store.dispatch("deleteregisteruser", id).then((response) => {
        if (response === "user deleted") {
          this.BucketsMessage = "User deleted!";
          this.getRegisterUsers();
          this.pageLoading = false;
        }
      });
    },
    deleteMember(id) {
      this.pageLoadingMembers = true;
      this.registerMember.tenantId = localStorage.getItem("tenantId");
      this.$store.dispatch("deleteregistermember", id).then((response) => {
        if (response === "user deleted") {
          this.BucketsMessage = "Member deleted!";
          this.getRegisterMembers();
          this.pageLoadingMembers = false;
        }
      });
    },

    currentAsset: function (asset) {
      var assetId = asset.assetId;
      var assetName = asset.assetName;
      this.analytics.EventCategory = "HomePage";
      this.analytics.ActionName =
        "HomePage_" +
        assetName.replace(/^./, assetName[0].toUpperCase()) +
        "_Click";
      this.analytics.Label = this.Email;
      this.analytics.Cid = this.Email;
      this.$store.dispatch("googleAnalytics", this.analytics);
      this.BucketData.currentAsset = assetId;
      console.log(this.BucketData.currentAsset);
    },
    register() {
      if (
        this.registerUser.firstName === "" ||
        this.registerUser.email === "" ||
        this.registerUser.password === ""
      ) {
        this.$alertify.error("Please fill all the required fields");
      }
      //  else if (!this.reg.test(this.registerUser.email)) {
      //     this.$alertify.error("Please enter a valid email address");
      //   } else if (this.registerUser.password.length < 6) {
      //     this.$alertify.error("Password must be minimum 6 characters");
      // }
      else {
        this.pageLoadingUsers = true;
        this.registerUser.tenantId = localStorage.getItem("tenantId");
        this.userModal = false;
        this.$store
          .dispatch("webregister", this.registerUser)
          .then((response) => {
            if (response === "This already exists") {
              this.pageLoadingUsers = false;
              this.$alertify.error("Email Already registered!");
              setTimeout(() => {
                this.$router.push("/");
              }, 2000);
            } else if (response === "user registered") {
              this. BucketsMessage = "User Registered";
              this.getRegisterUsers();
              // this.analytics.EventCategory = "SignUp_Page";
              // this.analytics.ActionName = "Register_Btn_Click";
              // this.analytics.Label = this.registerUser.email;
              // this.analytics.Cid = this.registerUser.email;
              // this.$store.dispatch("googleAnalytics", this.analytics);
              // this.$router.push("/EmailOtpVerification");
              this.registerUser.userId = "";
              this.registerUser.firstName = "";
              this.registerUser.lastName = "";
              this.registerUser.email = "";
              this.registerUser.tenantId = "";
              this.registerUser.password = "";
            } else if (response === "plugin code expired") {
              this.pageLoadingUsers = false;
              this.$alertify.error("This plugin code has been expired");
            } else if (response === "maximum limit reached") {
              this.pageLoadingUsers = false;
              this.$alertify.error(
                "User Limit has reached. Please write to admin on support@inkppt.com"
              );
            } else if (response === "invalid plugin code") {
              this.pageLoadingUsers = false;
              this.$alertify.error("Plugin code is invalid");
            } else if (response === "No Match Found") {
              this.pageLoadingUsers = false;
              this.$alertify.error("Plugin Code is Invalid");
            } else {
              this.pageLoadingUsers = false;
              this.$alertify.error("Registration failed");
            }
          });
      }
    },
    registerMembers() {
      if (
        this.registerMember.username === "" ||
       // this.registerMember.role === "" || //
        this.registerMember.password === ""
         ) {
        this.$alertify.error("Please fill all the required fields");
      }
      //  else if (!this.reg.test(this.registerUser.email)) {
      //     this.$alertify.error("Please enter a valid email address");
      //   } else if (this.registerUser.password.length < 6) {
      //     this.$alertify.error("Password must be minimum 6 characters");
      // }
      else {
        this.pageLoadingMembers = true;
        this.registerMember.tenantId = localStorage.getItem("tenantId");
        
              this.backendUserModal = false;
        this.$store
          .dispatch("registerAdmin", this.registerMember)
          .then((response) => {
            if (response === "This already exists") {
              this.pageLoadingMembers = false;
              this.$alertify.success("Member Already registered!");
              setTimeout(() => {
                this.$router.push("/");
              }, 2000);
            } else if (response === "member registered") {
              this.BucketsMessage = "Admin Added Successfully"
               this.pageLoadingMembers = false;
              this.getRegisterMembers();
              // this.analytics.EventCategory = "SignUp_Page";
              // this.analytics.ActionName = "Register_Btn_Click";
              // this.analytics.Label = this.registerUser.email;
              // this.analytics.Cid = this.registerUser.email;
              // this.$store.dispatch("googleAnalytics", this.analytics);
              // this.$router.push("/EmailOtpVerification");
              this.registerMember.Id = "";
              this.registerMember.username = "";
              this.registerMember.role = "";
              this.registerMember.password = "";
              this.registerMember.tenantId = "";
            } else if (response === "plugin code expired") {
              this.pageLoadingMembers = false;
              this.$alertify.error("This plugin code has been expired");
            } else if (response === "maximum limit reached") {
              this.pageLoadingMembers = false;
              this.$alertify.error(
                "User Limit has reached. Please write to admin on support@inkppt.com"
              );
            } else if (response === "invalid plugin code") {
              this.pageLoadingMembers = false;
              this.$alertify.error("Plugin code is invalid");
            } else if (response === "No Match Found") {
              this.pageLoadingMembers = false;
              this.$alertify.error("Plugin Code is Invalid");
            } else {
              this.pageLoadingMembers = false;
              this.$alertify.error("Updation failed");
            }
          });
      }
    },
    getRegisterUsers() {
      this.pageLoadingUsers = true;
      this.$store
        .dispatch("registerUsers", localStorage.getItem("tenantId"))
        .then((response) => {
          console.log(response);
          this.AllRegisterUser = response.data;
          this.pageLoadingUsers = false;
           if(this.BucketsMessage !=null){
          this.$alertify.success(this.BucketsMessage);
          }
        })
        .catch((error) => console.log(error));
    },
    getRegisterMembers() {
      this.pageLoadingMembers = true;
      this.$store
        .dispatch("registerMembers", localStorage.getItem("tenantId"))
        .then((response) => {
             this.pageLoadingMembers = false;
          this.AllRegisterMember = response.data;
          setInterval(this.pageLoadingMembers = false, 3000);
          if(this.BucketsMessage !=null){
          this.$alertify.success(this.BucketsMessage);
          }
        })
        .catch((error) => console.log(error));
        this.pageLoadingMembers = false;
    },
    editUser(userId) {
      this.userModal = true;
      const selectedUser = this.AllRegisterUser.find(
        (x) => x.userId === userId
      );
      this.registerUser.userId = selectedUser.userId;
      this.registerUser.firstName = selectedUser.firstName;
      this.registerUser.lastName = selectedUser.lastName;
      this.registerUser.email = selectedUser.email;
      this.registerUser.password = selectedUser.password;
      this.buttonText = "Update";
    },
    editMember(id) {
      console.log(id);
      this.backendUserModal = true;
      const selectedMember = this.AllRegisterMember.find(
        (x) => x.userId === id
      );
      this.registerMember.Id = selectedMember.userId;
      this.registerMember.username = selectedMember.firstName;
      this.registerMember.role = selectedMember.lastName;
      this.registerMember.tenantId = selectedMember.email;
      this.registerMember.password = selectedMember.password;
      this.buttonText = "Update";
    },
    editChildBucket(bucketId) {
      this.childBucketModal = true;
      const selectedBucket = this.BucketData.AllBuckets.find(
        (x) => x.assetId === bucketId
      );
      this.childbucketForm.bucketId = selectedBucket.assetId;
      this.childbucketForm.parentSelectedValue = selectedBucket.parentId;
      this.fileSelectedName = selectedBucket.assetLogo;
      this.childbucketForm.bucketName = selectedBucket.assetName;
      this.childbucketForm.assetTypeSelected = selectedBucket.assetTypeId;
      this.childbucketForm.bucketStatus = selectedBucket.bucketStatus;
      this.childbucketForm.viewStyleSelected = selectedBucket.viewStyleId;
      this.buttonText = "Update";
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    editParentBucket(bucketId) {
      this.masterBucketModal = true;
      const selectedBucket = this.BucketData.AllAssets.find(
        (x) => x.assetId === bucketId
      );
      this.parentBucketForm.bucketId = selectedBucket.assetId;
      this.fileSelectedName = selectedBucket.assetLogo;
      this.parentBucketForm.bucketName = selectedBucket.assetName;
      this.parentBucketForm.bucketStatus = selectedBucket.bucketStatus;
      this.buttonText = "Update";
      document.getElementById("parentFormDiv").scrollIntoView();
    },
    update() {
      if (
        this.registerUser.firstName === "" ||
        this.registerUser.email === "" ||
        this.registerUser.password === ""
      ) {
        this.$alertify.error("Please fill all the required fields");
      }
      // else if (!this.reg.test(this.registerUser.email)) {
      //     this.$alertify.error("Please enter a valid email address");
      //   } else if (this.registerUser.password.length < 6) {
      //     this.$alertify.error("Password must be minimum 6 characters");
      //   }  else {

      this.pageLoading = true;
    
      this.registerUser.tenantId = localStorage.getItem("tenantId");
      this.userModal = false;
      this.$store
        .dispatch("updatewebregister", this.registerUser)
        .then((response) => {
          if (response === "email already exists") {
            this.pageLoading = false;
            this.$alertify.success("Already registered!");
            setTimeout(() => {
              this.$router.push("/");
            }, 2000);
          } else if (response === "user updated") {
            this.BucketsMessage = "User Updated!"
            this.getRegisterUsers();
            // this.analytics.EventCategory = "SignUp_Page";
            // this.analytics.ActionName = "Register_Btn_Click";
            // this.analytics.Label = this.registerUser.email;
            // this.analytics.Cid = this.registerUser.email;
            // this.$store.dispatch("googleAnalytics", this.analytics);
            // this.$router.push("/EmailOtpVerification");
              this.registerUser.userId = "";
              this.registerUser.firstName = "";
              this.registerUser.lastName = "";
              this.registerUser.email = "";
              this.registerUser.tenantId = "";
              this.registerUser.password = "";
            this.buttonText = "Add";
           
          }
          // else if (response === "plugin code expired") {
          //   this.pageLoading = false
          //   this.$alertify.error("This plugin code has been expired")
          // }
          // else if (response === "maximum limit reached") {
          //   this.pageLoading = false
          //   this.$alertify.error("User Limit has reached. Please write to admin on support@inkppt.com")
          // }
          // else if (response === "invalid plugin code") {
          //   this.pageLoading = false
          //   this.$alertify.error("Plugin code is invalid")
          // }
          else if (response === "No Match Found") {
            this.pageLoading = false;
            this.$alertify.error("Plugin Code is Invalid");
          } else {
            this.pageLoading = false;
             this.pageLoadingBuckets = false;
            this.$alertify.error("Registration failed");
          }
        });
      // }
    },
    updateMember() {
      if (
        this.registerMember.username === "" ||
        this.registerMember.role === "" ||
        this.registerMember.password === ""
      ) {
        this.$alertify.error("Please fill all the required fields");
      }
      // else if (!this.reg.test(this.registerUser.email)) {
      //     this.$alertify.error("Please enter a valid email address");
      //   } else if (this.registerUser.password.length < 6) {
      //     this.$alertify.error("Password must be minimum 6 characters");
      //   }  else {
      this.pageLoadingMembers = true;
      this.registerMember.tenantId = localStorage.getItem("tenantId");      
            this.backendUserModal = false;
      this.$store
        .dispatch("updatewebregistermember", this.registerMember)
        .then((response) => {
          if (response === "username already exists") {
            this.pageLoading = false;
            this.$alertify.success("Already registered!");
            setTimeout(() => {
              this.$router.push("/");
            }, 2000);
          } else if (response === "member updated") {
            this.BucketsMessage = "Admin Updated";
            this.getRegisterMembers();
            // this.analytics.EventCategory = "SignUp_Page";
            // this.analytics.ActionName = "Register_Btn_Click";
            // this.analytics.Label = this.registerUser.email;
            // this.analytics.Cid = this.registerUser.email;
            // this.$store.dispatch("googleAnalytics", this.analytics);
            // this.$router.push("/EmailOtpVerification");
             this.registerMember.Id = "";
              this.registerMember.username = "";
              this.registerMember.role = "";
              this.registerMember.password = "";
              this.registerMember.tenantId = "";
            this.buttonText = "Add";
            
          }
          // else if (response === "plugin code expired") {
          //   this.pageLoading = false
          //   this.$alertify.error("This plugin code has been expired")
          // }
          // else if (response === "maximum limit reached") {
          //   this.pageLoading = false
          //   this.$alertify.error("User Limit has reached. Please write to admin on support@inkppt.com")
          // }
          // else if (response === "invalid plugin code") {
          //   this.pageLoading = false
          //   this.$alertify.error("Plugin code is invalid")
          // }
          else if (response === "No Match Found") {
            this.pageLoading = false;
             this.pageLoadingBuckets = false;
            this.$alertify.error("Plugin Code is Invalid");
          } else {
            this.pageLoading = false;
             this.pageLoadingBuckets = false;
            this.$alertify.error("Registration failed");
          }
        });
      // }
    },
    addChildBucket() {
      if (
        this.childbucketForm.parentSelectedValue === "" ||
        this.childbucketForm.bucketName === "" ||
        this.childbucketForm.assetTypeSelected === "" ||
        this.childbucketForm.viewStyleSelected === ""
      ) {
        this.$alertify.error("Please fill all the required fields");
      }
      // else if (!this.reg.test(this.registerUser.email)) {
      //     this.$alertify.error("Please enter a valid email address");
      //   } else if (this.registerUser.password.length < 6) {
      //     this.$alertify.error("Password must be minimum 6 characters");
      //   }  else {
      this.pageLoadingBuckets = true;
      this.childbucketForm.tenantId = localStorage.getItem("tenantId");
      this.childbucketForm.bucketLogoName = this.fileSelectedName;
       this.childBucketModal = false;
     
      this.$store
        .dispatch("addchildbuckets", this.childbucketForm)
        .then((response) => {
          if (response === "This Bucket Name already exists"){
            this.pageLoading = false;
            this.$alertify.error("This Bucket already exists");
            this.childBucketModal = false;
          } else if (response === "Child Bucket Inserted") {
            
             this.BucketsMessage = "Bucket Inserted Successfully";
            this.getBuckets();
            // this.analytics.EventCategory = "SignUp_Page";
            // this.analytics.ActionName = "Register_Btn_Click";
            // this.analytics.Label = this.registerUser.email;
            // this.analytics.Cid = this.registerUser.email;
            // this.$store.dispatch("googleAnalytics", this.analytics);
            // this.$router.push("/EmailOtpVerification");
            this.childbucketForm.parentSelectedValue = "";
            this.childbucketForm.bucketName = "";
            this.childbucketForm.assetTypeSelected = "";
            this.childbucketForm.viewStyleSelected = "";
            this.childbucketForm.bucketLogo = "";
            this.childbucketForm.bucketLogoName = "";
            this.childbucketForm.logoChanged = false;
            this.buttonText = "Add";
           this.childBucketModal = false;
           
            // this.$alertify.success("Bucket Inserted Successfully");
          }
          // else if (response === "plugin code expired") {
          //   this.pageLoading = false
          //   this.$alertify.error("This plugin code has been expired")
          // }
          // else if (response === "maximum limit reached") {
          //   this.pageLoading = false
          //   this.$alertify.error("User Limit has reached. Please write to admin on support@inkppt.com")
          // }
          // else if (response === "invalid plugin code") {
          //   this.pageLoading = false
          //   this.$alertify.error("Plugin code is invalid")
          // }
          else if (response === "No Match Found") {
            this.pageLoadingBuckets = false;
            this.$alertify.error("Plugin Code is Invalid");
            this.childBucketModal = false;
          } else {
            this.pageLoadingBuckets = false;
            this.$alertify.error(response);
            this.childBucketModal = false;
          }
        });
      // }
    },
    updateChildBucket() {
      if (
        this.childbucketForm.parentSelectedValue === "" ||
        this.childbucketForm.bucketName === "" ||
        this.childbucketForm.assetTypeSelected === "" ||
        this.childbucketForm.viewStyleSelected === "" 
      ) {
        this.$alertify.error("Please fill all the required fields");
      }
      // else if (!this.reg.test(this.registerUser.email)) {
      //     this.$alertify.error("Please enter a valid email address");
      //   } else if (this.registerUser.password.length < 6) {
      //     this.$alertify.error("Password must be minimum 6 characters");
      //   }  else {
      this.pageLoadingBuckets = true;
      this.childbucketForm.tenantId = localStorage.getItem("tenantId");
      this.childbucketForm.bucketLogoName = this.fileSelectedName;
       this.childBucketModal = false;  
         this.$store
        .dispatch("updatechildbuckets", this.childbucketForm)
        .then((response) => {
          if (response === "This Bucket already exists") {
            this.pageLoading = false;
            this.$alertify.success("Bucket Name already exists!");
          } else if (response === "Child Bucket Updated") {
               this.BucketsMessage = "Bucket Updated Successfully!";
            this.getBuckets();
            // this.analytics.EventCategory = "SignUp_Page";
            // this.analytics.ActionName = "Register_Btn_Click";
            // this.analytics.Label = this.registerUser.email;
            // this.analytics.Cid = this.registerUser.email;
            // this.$store.dispatch("googleAnalytics", this.analytics);
            // this.$router.push("/EmailOtpVerification");
            this.childbucketForm.parentSelectedValue = "";
            this.childbucketForm.bucketName = "";
            this.childbucketForm.assetTypeSelected = "";
            this.childbucketForm.viewStyleSelected = "";
            this.childbucketForm.bucketLogo = "";
            this.childbucketForm.bucketLogoName = "";
            this.childbucketForm.logoChanged = false;
            this.buttonText = "Add";
           
         
          }
          // else if (response === "plugin code expired") {
          //   this.pageLoading = false
          //   this.$alertify.error("This plugin code has been expired")
          // }
          // else if (response === "maximum limit reached") {
          //   this.pageLoading = false
          //   this.$alertify.error("User Limit has reached. Please write to admin on support@inkppt.com")
          // }
          // else if (response === "invalid plugin code") {
          //   this.pageLoading = false
          //   this.$alertify.error("Plugin code is invalid")
          // }
          else if (response === "No Match Found") {
            this.pageLoading = false;
            this.$alertify.error("Plugin Code is Invalid");
          } else {
            this.pageLoading = false;
            this.$alertify.error("Bucket Updation Failed");
             this.childBucketModal = false;
          }
        });
      // }
    },
    addParentBucket() {
      if (
        this.parentBucketForm.bucketName === "" 
      ) {
        this.$alertify.error("Please fill all the required fields");
      }
      // else if (!this.reg.test(this.registerUser.email)) {
      //     this.$alertify.error("Please enter a valid email address");
      //   } else if (this.registerUser.password.length < 6) {
      //     this.$alertify.error("Password must be minimum 6 characters");
      //   }  else {
      this.pageLoadingBuckets = true;
      this.parentBucketForm.tenantId = localStorage.getItem("tenantId");
      this.parentBucketForm.bucketLogoName = this.fileSelectedName;
      this.masterBucketModal = false;
      this.$store
        .dispatch("addparentbuckets", this.parentBucketForm)
        .then((response) => {
          if (response === "This Bucket already exists") {
             this.pageLoading = false;
             this.masterBucketModal = false;
            this.$alertify.error("This Bucket already exists");
          } else if (response === "Parent Bucket Inserted") {
              this.BucketsMessage = "Bucket Inserted Successfully";
            this.getBuckets();
            // this.analytics.EventCategory = "SignUp_Page";
            // this.analytics.ActionName = "Register_Btn_Click";
            // this.analytics.Label = this.registerUser.email;
            // this.analytics.Cid = this.registerUser.email;
            // this.$store.dispatch("googleAnalytics", this.analytics);
            // this.$router.push("/EmailOtpVerification");
            this.parentBucketForm.bucketName = "";
            this.parentBucketForm.bucketLogoName = "";
            this.parentBucketForm.bucketLogo = "";
            this.parentBucketForm.formData = null;
             this.parentBucketForm.logoChanged = false;
            this.buttonText = "Add";
            
         
          }
          // else if (response === "plugin code expired") {
          //   this.pageLoading = false
          //   this.$alertify.error("This plugin code has been expired")
          // }
          // else if (response === "maximum limit reached") {
          //   this.pageLoading = false
          //   this.$alertify.error("User Limit has reached. Please write to admin on support@inkppt.com")
          // }
          // else if (response === "invalid plugin code") {
          //   this.pageLoading = false
          //   this.$alertify.error("Plugin code is invalid")
          // }
          else if (response === "No Match Found") {
          this.pageLoadingBuckets = false;
            this.$alertify.error("Plugin Code is Invalid");
          } else {
             this.pageLoadingBuckets = false;
            this.$alertify.error(response);
          }
        });
      // }
    },
    updateParentBucket() {
      if (
        this.parentBucketForm.bucketName === ""
        
      ) {
        this.$alertify.error("Please fill all the required fields");
      }
      // else if (!this.reg.test(this.registerUser.email)) {
      //     this.$alertify.error("Please enter a valid email address");
      //   } else if (this.registerUser.password.length < 6) {
      //     this.$alertify.error("Password must be minimum 6 characters");
      //   }  else {
      this.pageLoadingBuckets = true;
      this.parentBucketForm.tenantId = localStorage.getItem("tenantId");
      this.parentBucketForm.bucketLogoName = this.fileSelectedName;
        this.masterBucketModal = false;      
               this.$store
        .dispatch("updateparentbuckets",  this.parentBucketForm)
        .then((response) => 
        {
          if (response === "This Bucket already exists") {
         this.pageLoadingBuckets = false;
            this.$alertify.success("Bucket Name already exists!");
          } else if (response === "Parent Bucket Updated") {
                this.BucketsMessage = "Bucket Updated Successfully!";
            this.getBuckets();
            this.parentBucketForm.bucketName = "";
            this.parentBucketForm.bucketLogo = "";
            this.parentBucketForm.bucketLogoName = "";
            this.parentBucketForm.formData = null;
            this.buttonText = "Add";
            this.parentBucketForm.logoChanged = false;
           this.masterBucketModal = false;
            this.pageLoadingBuckets = false;
          }
          // else if (response === "plugin code expired") {
          //   this.pageLoading = false
          //   this.$alertify.error("This plugin code has been expired")
          // }
          // else if (response === "maximum limit reached") {
          //   this.pageLoading = false
          //   this.$alertify.error("User Limit has reached. Please write to admin on support@inkppt.com")
          // }
          // else if (response === "invalid plugin code") {
          //   this.pageLoading = false
          //   this.$alertify.error("Plugin code is invalid")
          // }
          else if (response === "No Match Found") {
            this.pageLoadingBuckets = false;
            this.$alertify.error("Plugin Code is Invalid");
             this.masterBucketModal = false;
          } else {
            this.pageLoadingBuckets = false;
            this.$alertify.error("Registration failed");
             this.masterBucketModal = false;
          }
        });
      
      
      // }
    },
    deleteBucket(id) {
      this.pageLoadingBuckets = true;
      this.registerUser.tenantId = localStorage.getItem("tenantId");
      this.$store.dispatch("deletebucket", id).then((response) => {
        if (response === "bucket deleted") {
            this.BucketsMessage = "Bucket deleted!";
          this.getBuckets();
          this.pageLoading = false;
       
        }
      });
    },
    getTenantInfo(){
       this.$store
        .dispatch("tenantInfo", localStorage.getItem("tenantId"))
        .then((response) => {
          console.log(response.data[0].email);
          this.TenantInfo = response.data;
          if(response.data[0].email == 'Pending'){
            const data ={
                  userId : localStorage.getItem("userId"),
                  tenantId : localStorage.getItem("tenantId")
            };
             this.$store
        .dispatch("signUpInfo", data)
        .then((res)=> { 
          console.log(res);
        this.$alertify.error("Complete Your Payment First");
          this.$router.push({name:'SignUpTenantUpdate', params:{email: res.data[0].email, username: res.data[0].username, password: res.data[0].password, contact: res.data[0].contact, country: res.data[0].country, address: res.data[0].address, pin: res.data[0].pin, name: res.data[0].name, gst: res.data[0].gst, notification: res.data[0].notification, marketingPromotion: res.data[0].marketingPromotion, systemUpdate: res.data[0].systemUpdate, pluginCode: res.data[0].pluginCode}})
          }
         )            
          }
          else
          {
             this. BucketsMessage = null;
             this.getBuckets();             
             this.getRegisterUsers();
             this.getRegisterMembers();
             this.backendUserModal = false;
             this.userModal = false;
          }
        });
    },
    getBuckets() {
      this.pageLoadingBuckets = true;
      axios
        .get(
          common.awsServerlesslamdaapi +
            "Bucket?tenantId=" +
            localStorage.getItem("tenantId")
        )
        .then((response) => {
          // this.BucketData.SlideBuckets = response.data.slideBuckets;
          // this.BucketData.IconBuckets = response.data.iconBuckets;
          // this.BucketData.ImageBuckets = response.data.imageBuckets;
          // this.BucketData.GifBuckets = response.data.gifBuckets;
          // this.BucketData.ChartBuckets = response.data.chartBuckets;
          // this.BucketData.SmartArtBuckets = response.data.smartArtBuckets;
          // this.BucketData.ThumbNails = response.data.thumbNails;
          this.BucketData.AllAssets = response.data.allAssets;
          this.BucketData.AllBuckets = response.data.allBucketsWeb;
          this.pageLoadingBuckets = false;
          if(this.BucketsMessage !=null){
          this.$alertify.success(this.BucketsMessage);
          }
        });
    },
  },
  mounted(){
    if (!localStorage.getItem("tenantId")) {
      this.$router.push("/");
    }
   
  },
  created() {
    this.getTenantInfo();   
  },
  computed: {
    baseurlIcons() {
      return common.baseurl + "Images/Assets/Buckets/";
    },
    baseurlLogos() {
      return common.baseurl + "Images/Logos/";
    },
  },
};
</script>
<style scoped>
@import url("https://unpkg.com/@webpixels/css@1.1.5/dist/index.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/bootstrap-icons/1.4.0/font/bootstrap-icons.min.css");
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 500px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.nav-item active {
  background: #00b0ac;
  background-color: #00b0ac;
}
</style>
