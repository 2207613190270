<template>
    <div>
      <div v-if="pageLoading === true" id="preloader_id">
      <Preloader />
    </div>
        <Navbar />
      <div class="container">
        <div class="container mt-3" style="margin-left: 5px;">
           <div class="d-flex align-items-center justify-content-center">
      <div
        style="color: #19D4BD; margin-left: 3%; margin-top: 80%;"
        class="la-square-loader la-2x"
        v-if="pageLoading === true"
      >
        <div></div>
      </div>
    </div>
          <div   v-if="pageLoading === false" class="row" style="margin-left: 5px;justify-content: center;">
            <div class="col sign-form">
              <form
              @keyup.enter="login"
                action=""
                method="post"
                id="fileForm"
                role="form"
                
              >
                <header class="header-part text-center">
                  <h2 style="color: #4C156A; margin-bottom: 0px;">
                    Sign In
                  </h2>
                </header>
                <fieldset>
                  <div class="form-group">
                    <label for="email">Email Address </label>
                    <input
                    v-model="loginUser.email"
                      class="form-control"
                      required
                      type="text"
                      name="email"
                    />
                  </div>
                  <div class="form-group">
                    <label for="password">Password </label>
                    <input
                      required
                    v-model="loginUser.password"
                      name="password"
                      type="password"
                      class="form-control inputpass"
                      minlength="4"
                      maxlength="16"
                    />
                  </div>
                  <div>
                    <div class="load-more-btn mt-3 text-center" @click="login">
                      <a>Sign In </a>
                    </div>
                    <div class="forgotPass mt-3">
                      <a> .</a>
                    </div>

                      <!-- <div class="forgotPass mt-3" @click="forgotPass">
                      <a> Forgot Password ??</a>
                    </div> -->
                    <h5 class="mt-5" style="color: #4C156A;">New User?</h5>
                    <div style="margin-left: 120px; margin-top: -40px;">
                      <div class="load-more-btn" @click="registerHere">
                        <a>Sign Up Here</a>
                      </div>
                    </div>
                    <blockquote class="quote">
                      Create your Account now and get access to thousands of
                      Icons, Images, Charts, Gifs, and SmartArts.
                    </blockquote>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

// export default {
  
//   methods:{
//       login() {
//           this.$router.push("/Dashboard");
//       },
//       forgotPass() {
//           this.$router.push("/ForgotPassword");
//       },
//       registerHere(){
//         this.$router.push("SignUpTenant")
//       }
//   }
// }
import Preloader from "./Preloader.vue";
import Navbar from '@/components/Navbar.vue';
export default {
  name: "SignIn",
  components:{
      Navbar,
      Preloader
  },
  data() {
    return {
      loginUser: {
        email: "",
        password: ""
      },
      analytics: {
        EventCategory: "",
        ActionName: "",
        Label: "",
        Cid: ""
      },
      pageLoading: false,
      loggedIn: "",
      // eslint-disable-next-line
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
    };
  },
  methods: {
    login() {
      // this.analytics.EventCategory = "Login";
      // this.analytics.ActionName = "Login_Button_Clicked";
      // this.analytics.Label = this.loginUser.email;
      // this.analytics.Cid = this.loginUser.email;
      // this.$store.dispatch("googleAnalytics", this.analytics);
      if (this.loginUser.email === "" || this.loginUser.password === "") {
        this.$alertify.error("Please fill the required fields");
      }
      //  else if (!this.reg.test(this.loginUser.email)) {
      //   this.$alertify.error("Please enter a valid email address");
      // }
       else {
        this.pageLoading = true;
        this.$store.dispatch("login", this.loginUser).then(response => {
          console.log(response);
          if (response[0] === "Invalid email or Password") {
            console.log("Status Invalid");
            this.pageLoading = false;
            this.$alertify.error(
              "email or Password is Incorrect! Please Try again"
            );
          } else if (response[0] === "Email and password matched") {
            //  common.setUserStatus();

            localStorage.setItem("UserName", response[1]);
            localStorage.setItem("tenantId", response[2]);
             localStorage.setItem("userId", response[3]);
           this.$alertify.success(
              "Login Successfully"
            );
            this.$router.push("/Dashboard");
          }
        });
      }
    },
    registerHere() {
      this.analytics.EventCategory = "Login";
      this.analytics.ActionName = "Register_Button_Clicked";
      this.analytics.Label = this.loginUser.email;
      this.analytics.Cid = this.loginUser.email;
      this.$store.dispatch("googleAnalytics", this.analytics);
      this.$router.push("/SignUpTenant");
    },
    forgotPass() {
      this.analytics.EventCategory = "Login";
      this.analytics.ActionName = "ForgotPassword_Button_Clicked";
      this.analytics.Label = this.loginUser.email;
      this.analytics.Cid = this.loginUser.email;
      this.$store.dispatch("googleAnalytics", this.analytics);
      this.$router.push("/ForgotPassword");
    }
  }
};



</script>

<style scoped>
/* .form-group input{
    border: none;
    border-bottom: 1px solid #ccc;
}
.form-group input:focus{
    border: none;
    border-bottom: 2px solid #ccc;
} */
.load-more-btn{
 background: #00B0AC;
 padding-top: 0px !important;
 padding: 2px 8px;
}
.load-more-btn a {
  max-width: 300px !important;
  line-height: 35px;
  
}
.load-more-btn:hover {
  background: #4c156a;
  color: #fff;
}
.quote {
  margin: 5px;
  margin-top: 14px;
  font-size: 14px;
  color: #4c156a;
  text-align: center;
}
.forgotPass {
  text-align: center;
}
.forgotPass a:hover {
  cursor: pointer;
}
.sign-form{
  max-width:30%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  padding: 24px;
}

</style>
