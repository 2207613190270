<template>
    <div class="container">
       <Navbar />
    <div>
      
      <div class="container">
        <div class="row" style="margin-left: 5px; justify-content: center;">
          <div class="col forgotPassword">
            <form
              action=""
              method="post"
              id="fileForm"
              role="form"
            >
              <header class="header-part">
                <h3
                style="color: #4C156A; margin-bottom: 0px; margin-left: 20%;margin-top:7px"
                >
                Forgot Password
                </h3>
              </header>
              <fieldset>
                <div class="form-group">
                  <label for="email">Email Address </label>
                  <input
                    class="form-control"
                    required
                    type="text"
                    name="email"
                  />
                </div>
                <!-- <div class="mt-2" v-if="PasswordNotMatched === true">
                    <small style="color: #red;">Password must match</small>
                    </div> -->
                <div class="load-more-btn mt-3 text-center" @click="checkUser">
                  <a>Get OTP </a>
                </div>
                <div class="mt-3 text-center">
                  <div class="load-more-btn" @click="cancel">
                    <a>Cancel </a>
                  </div>
                </div>
                <div>
                  <div style="padding-top:5%; font-size:20px" @click="signin">
                    <a>Sign In!</a>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
    import Navbar from '@/components/Navbar.vue'
    export default {
        name: 'ForgotPassword',
        components:{
            Navbar
        },
        methods: {
            signin() {
                this.$router.push("/");
            },
            checkUser() {
                this.$router.push("/EmailOtpVerification");
            }
        }
    }

</script>
<style scoped>
.load-more-btn{
 background: #00B0AC;
 padding-top: 0px !important;
 padding: 2px 8px;
}
.load-more-btn a {
  max-width: 300px !important;
  line-height: 35px;
  
}
.load-more-btn:hover {
  background: #4c156a;
  color: #fff;
}
.form-control {
  width: 100%;
}
label small {
  color: #678 !important;
}
span.req {
  color: maroon;
  font-size: 112%;
}
.header-title h3 {
  text-align: center;
  margin-top: 7px;
}
.forgotPassword{
  max-width:30%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  padding: 24px;
}
</style>
