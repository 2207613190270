<template>
  <div>
    <Navbar />
    <!-- MultiStep Form -->
    <div id="grad1">
      <div v-if="pageLoadingUsers === true" id="preloader_id">
        <Preloader />
      </div>
      <div class="row justify-content-center mt-0">
        <div
          class="col-11 col-sm-9 col-md-7 col-lg-6 text-center p-0 mt-3 mb-2"
        >
          <div class="card px-0 pt-4 pb-0 mt-3 mb-3 signup-wizard">
            <h2>
              <strong style="color: rgb(76, 21, 106)"
                >Sign Up Your User Account</strong
              >
            </h2>
            <p>Fill all form field to go to next step</p>
            <div class="row">
              <div class="col-md-12 mx-0">
                <button
                  class="load-more-btn mt-3"
                  style="float: right; margin-right: 10px"
                  @click="pushToSignIn()"
                >
                  SignIn
                </button>
                <form id="msform">
                  <!-- progressbar -->
                  <ul id="progressbar">
                    <li class="active" id="account">
                      <strong>Account</strong>
                    </li>
                    <li id="personal"><strong>Address</strong></li>
                    <li id="payment"><strong>Information</strong></li>
                    <li id="payment"><strong>Plans</strong></li>
                    <li id="confirm"><strong>Finish</strong></li>
                  </ul>
                  <!-- fieldsets -->
                  <fieldset>
                    <div class="form-card">
                      <h2 class="fs-title">Account Information</h2>
                      <input
                        type="email"
                        v-model="signUp.email"
                        name="email"
                        placeholder="Email Id"
                        @input="checkEmail"
                      />
                      <span class="text-danger">
                        {{ signUp.emailErrMsg }}
                      </span>
                      <input
                        type="text"
                        v-model="signUp.username"
                        name="uname"
                        placeholder="UserName"                        
                      />
                       
                      <input
                        type="password"
                        v-model="signUp.password"
                        name="password"
                        placeholder="Password"
                      />
                      <input
                        type="text"
                        v-model="signUp.contact"
                        name="contact"
                        placeholder="Contact Number"
                        @input="acceptNumber"
                      />
                    </div>
                    <input
                      type="button"
                      name="next"
                      class="next action-button"
                      @click="nextStep()"
                      value="Next Step"
                    />
                  </fieldset>
                  <fieldset>
                    <div class="form-card">
                      <!-- <h2 class="fs-title">Personal Information</h2>  -->
                      <input
                        type="text"
                        v-model="signUp.country"
                        name="country"
                        placeholder="Country"
                      />
                      <input
                        type="text"
                        v-model="signUp.address"
                        name="address"
                        placeholder="Address"
                      />
                      <input
                        type="text"
                        v-model="signUp.pin"
                        name="pin"
                        placeholder="Pin Code"
                      />
                      <!-- <input type="text" name="phno_2" placeholder="Alternate Contact No." /> -->
                    </div>
                    <input
                      type="button"
                      name="previous"
                      class="previous action-button-previous"
                      value="Previous"
                    />
                    <input
                      type="button"
                      name="next"
                      class="next action-button"
                      value="Next Step"
                    />
                  </fieldset>
                  <fieldset>
                    <div class="form-card">
                      <input
                        type="text"
                        v-model="signUp.organization"
                        name="organization"
                        placeholder="Organization"
                         @input="checkOrganization"
                      />
                       <span class="text-danger">
                        {{ signUp.organizationErrMsg }}
                      </span>
                      <input
                        type="text"
                        v-model="signUp.gst"
                        name="gst"
                        placeholder="GST"
                      />
                      <div class="form-group">
                        <div class="form-check">
                          <input
                            type="checkbox"
                            class=""
                            value="notification"
                            id="notification"
                            v-model="signUp.notification"
                            style="
                              width: 15px;
                              height: 15px;
                              margin: 10px;
                              padding: 0px;
                            "
                          />
                          <label class="form-check-label" for="exampleCheck1"
                            >Notifications</label
                          >
                          <br />
                          <input
                            type="checkbox"
                            class=""
                            value="marketingPromotion"
                            id="marketingPromotion"
                            v-model="signUp.marketingPromotion"
                            style="
                              width: 15px;
                              height: 15px;
                              margin: 10px;
                              padding: 0px;
                            "
                          />
                          <label class="form-check-label" for="exampleCheck1"
                            >Marketig and Promotion</label
                          >
                          <br />
                          <input
                            type="checkbox"
                            class=""
                            value="systemUpdate"
                            id="systemUpdate"
                            v-model="signUp.systemUpdate"
                            style="
                              width: 15px;
                              height: 15px;
                              margin: 10px;
                              padding: 0px;
                            "
                          />
                          <label class="form-check-label" for="exampleCheck1"
                            >System updates and new features</label
                          >
                        </div>
                      </div>
                      <button
                        class="btn btn-sm btn-neutral"
                        @click="conditionModalRoute"
                      >
                        Term & Conditions
                      </button>
                      <transition name="modal" v-if="conditionModal">
                        <div class="modal-mask">
                          <div
                            class="model-fade"
                            style="
                              width: 40%;
                              height: 95%;
                              margin-left: auto;
                              margin-right: auto;
                              margin-top: 15px;
                            "
                          >
                            <!-- <div class="modal-container"> -->
                            <div class="d-flex justify-content-center">
                              <div
                                class="
                                  card
                                  px-5
                                  pt-8
                                  pb-0
                                  mt-3
                                  mb-3
                                  signup-wizard
                                "
                                style="margin-bottom: 20px"
                              >
                                <h2 class="d-flex justify-content-center">
                                  <strong style="color: rgb(76, 21, 106)"
                                    >INK PPT Term of use</strong
                                  >
                                </h2>
                                <span
                                  class="d-flex justify-content-center"
                                  style=""
                                  >INK PPT Plus / INK PPT +Terms of use</span
                                >
                                <hr />
                                <div
                                  style="
                                    display: fix;
                                    height: 700px;
                                    overflow: auto;
                                  "
                                >
                                  <p><strong>July 2020</strong></p>
                                  <br />
                                  <p>
                                    These terms of use are made for the MS
                                    Office Plugin, Website named as INK PPT Plus
                                    / INK PPT + which is managed and controlled
                                    by Renure Creations Private Limited, a
                                    company registered under Companies Act 2013
                                    having its registered office at D-60, Preet
                                    Vihar, New Delhi, India-110092 and having
                                    its corporate office at 1335A, 1st Floor,
                                    Sector 15 Part 2, Gurguram, Haryana,
                                    India-122001 <strong>(“Company”)</strong>
                                  </p>
                                  <br />
                                  <p>
                                    These terms of use are made to control and
                                    govern the use of INK PPT Plus / INK PPT +
                                    by the users, including any of its sections
                                    <strong>(“Plugin, Website“);</strong> as
                                    well as the services rendered through the
                                    same (<strong>“Service”</strong> or
                                    <strong> “Services“</strong>, as applicable)
                                    which include the download and use of
                                    certain content.
                                  </p>
                                  <br />
                                  <p>
                                    When you use the Services of this Plugin,
                                    Website in any way, you agree to the Terms
                                    and any applicable Licenses.
                                    <strong
                                      >If you do not agree with the Terms and
                                      the applicable Licenses, you may not use
                                      the Service</strong
                                    >.
                                  </p>
                                  <br />
                                  <p>
                                    We may change the Terms at any time by
                                    posting the new version(s) to our website(s)
                                    thus we recommend that the Terms are
                                    reviewed on a regular basis by the User. We
                                    may also notify you via email or a notice in
                                    our platform, and we may require you to
                                    consent to the updated Terms and/or Licenses
                                    before you can use the Services further. In
                                    all cases your use of the Services is always
                                    subject to the most current version of the
                                    Terms and Licenses.
                                  </p>
                                  <br />
                                  <p>
                                    Some Services provided through the Plugin,
                                    Website may be subject to specific
                                    conditions or instructions that must be
                                    accepted by the User prior to the provision
                                    of the relevant Service. These specific
                                    conditions may be imposed by the Company or
                                    by third parties. Such specific conditions
                                    shall apply in addition to the Terms and, in
                                    case of conflict, shall supersede the Terms.
                                    Accordingly, the User must read and accept
                                    such specific conditions before the
                                    provision of the relevant Service.
                                  </p>
                                  <br />
                                  <p><strong>Our Services :</strong></p>
                                  <br />
                                  <p>
                                    The user can download presentation assets
                                    (Slides, Icons, GIFs, Images & other
                                    resources) including without limitation,
                                    text files and graphic resources such as
                                    icons, illustrations, photographs and videos
                                    with its corresponding audio.
                                  </p>
                                  <br />
                                  <p><strong>Acceptable Use</strong></p>
                                  <br />
                                  <p>
                                    In particular and without limitation, User
                                    undertakes that they will access and use the
                                    content of the website for legitimate
                                    purpose and with bonafide intentions. The
                                    User agrees not to use the Services
                                    negligently, for fraudulent purposes or in
                                    an unlawful manner that could damage the
                                    image, interests or rights of the Website or
                                    third parties.
                                  </p>
                                  <br />
                                  <p>
                                    The User will not interfere with the
                                    functioning of the Plugin, Website or in the
                                    Services. The User agrees not to carry out
                                    any action that may damage, make
                                    unavailable, overload, deteriorate or impede
                                    the normal use of the Plugin, Website or the
                                    Services, which may impact the security of
                                    the Plugin, Website or the Services, or
                                    which may in any way interfere with the
                                    Services offered by the Company. The use of
                                    robots, spiders or any other mechanism,
                                    mobile application, program or tool to
                                    access, copy or control any part of the
                                    Website or the Services in any way which is
                                    contrary to the ordinary use of the Plugin,
                                    Website or which infringes the Company’s
                                    interests (without its express prior
                                    authorization) is strictly prohibited.
                                    Likewise, obtaining or attempting to obtain
                                    the contents of the Plugin, Website using
                                    any method or system not expressly
                                    authorized by the Company or which is not
                                    the ordinary method of accessing the Plugin,
                                    Website is also strictly prohibited.
                                  </p>
                                  <br />
                                  <p>
                                    The rights granted to the User under these
                                    Terms are personal and shall not be assigned
                                    to any third party (including affiliates or
                                    entities part of the same group of
                                    companies) totally or partially, by any
                                    mean, without the prior, express and written
                                    consent from the Company.
                                  </p>
                                  <br />
                                  <p>
                                    When providing the Services, the Plugin,
                                    Website can publish advertising either
                                    related or not to the searched contents or
                                    the Services, which is expressly accepted by
                                    the User.
                                  </p>
                                  <br />
                                  <p>
                                    You may not (yourself or through a third
                                    party):
                                  </p>
                                  <br />
                                  <p>
                                    (a) use the Services to harm, threaten, or
                                    harass anyone,
                                  </p>
                                  <br />
                                  <p>
                                    (b) use the Services and/or the non-public
                                    information you gain from using the Services
                                    to create similar services,
                                  </p>
                                  <br />
                                  <p>
                                    c) use any automated process (e.g., a bot)
                                    with the Service,
                                  </p>
                                  <br />
                                  <p>
                                    (d) use the Services in any manner or for
                                    any purpose other than as expressly
                                    permitted by the Terms, the Licenses and the
                                    information available on our websites,
                                  </p>
                                  <br />
                                  <p>
                                    (e) remove, obscure or alter any proprietary
                                    rights notice pertaining to the Service,
                                    including notices on any software, apps, and
                                    documentation;
                                  </p>
                                  <br />
                                  <p>
                                    (f) access or use the Services in a way
                                    intended to avoid incurring fees or
                                    exceeding usage limits or quotas;
                                  </p>
                                  <br />
                                  <p>
                                    (g) use the Services in connection with
                                    anything misleading or illegal or post
                                    anything that is unlawful, defamatory,
                                    threatening, pornographic, abusive,
                                    libellous or otherwise objectionable or that
                                    encourages criminal conduct,
                                  </p>
                                  <br />
                                  <p>
                                    (h) attempt to access other accounts,
                                    computer systems or networks not covered by
                                    the Terms, through password mining or any
                                    other means, or
                                  </p>
                                  <br />
                                  <p><strong>NO WARRANTY</strong></p>
                                  <br />
                                  <p>
                                    The User acknowledges and agrees that
                                    he/she/it uses the Plugin, Website and its
                                    Services at their own risk and under the
                                    User’s responsibility and, therefore, the
                                    Company does not accept any responsibility
                                    for misuse or use in breach of these Terms.
                                  </p>
                                  <br />
                                  <p>
                                    The User will be responsible for any damages
                                    to the Company resulting from the User’s use
                                    of the Plugin, Website and the Services in
                                    breach of the Terms and accepts to indemnify
                                    the Company and its directors, employees,
                                    agents and representatives from any
                                    liability in which they may incur as a
                                    result of the User’s breach of these Terms.
                                  </p>
                                  <br />
                                  <p>
                                    The Company does not warrant the
                                    availability or continuity of the Plugin,
                                    Website or the Services, neither its
                                    reliability, quality, completeness, accuracy
                                    or whether they are fit for a specific
                                    purpose or activity.
                                  </p>
                                  <br />
                                  <p>
                                    As way of example and without limitation,
                                    the Company shall not be liable for any
                                    damages that may result from:
                                  </p>
                                  <br />
                                  <ul>
                                    <li>
                                      Interruptions, viruses, technical
                                      problems, interferences, omissions,
                                      unavailability, power cuts, failure of the
                                      telecommunication networks or the User’s
                                      equipment which are not the Company’s
                                      responsibility.
                                    </li>
                                    <br />
                                    <li>
                                      Delays or unavailability of the Plugin,
                                      Website and the Services due to
                                      deficiencies or traffic overload on the
                                      Internet, in the communication network or
                                      the electricity grid.
                                    </li>
                                    <br />
                                    <li>Third-party actions.</li>
                                    <br />
                                    <li>
                                      Unavailability of the Plugin, Website and
                                      the Services due to maintenance or
                                      software updates.
                                    </li>
                                    <br />
                                    <li>
                                      Any other event beyond the Company’s
                                      direct control.
                                    </li>
                                    <br />
                                  </ul>
                                  <p>
                                    SAVE FOR THE COMPANY’S WILFUL MISCONDUCT,
                                    AND THOSE CASES IN WHICH DUE TO THE SPECIFIC
                                    CIRCUMSTANCES OF THE USER INVOLVED OR THE
                                    NATURE OF THE MATTER, APPLICABLE LAW
                                    PROVIDES THAT LIABILITY CANNOT BE LIMITED BY
                                    AGREEMENT, USE OF THE WEBSITE AND THE
                                    SERVICES IS AT SOLE RISK OF THE USER AND THE
                                    COMPANY SHALL NOT BE LIABLE FOR ANY DAMAGE
                                    OF ANY KIND CAUSED TO THE USER AS A RESULT
                                    OF USING THE PLUGIN, WEBSITE AND/OR THE
                                    SERVICES.
                                  </p>
                                  <br />
                                  <p>
                                    <strong>LIMITATION OF LIABILITY</strong>
                                  </p>
                                  <br />
                                  <p>
                                    To the extent not prohibited by law, the
                                    Covered Entity/Entities shall not be liable
                                    for any incidental, special, indirect,
                                    consequential or similar damages, including
                                    lost profits, damages arising from loss of
                                    information (including your content),
                                    business interruption, regardless of the
                                    theory of liability (contract, tort or
                                    otherwise) and even if we have been advised
                                    of the possibility of such damages. In no
                                    event shall the Covered Entities’ total
                                    aggregate liability for all damages arising
                                    under or related to the Services or any
                                    other Final Draft products or services
                                    exceed the greater of $100 or the amount you
                                    paid for the Services in the three months
                                    preceding the event giving rise to the
                                    liability. These limits apply even if a
                                    stated remedy fails of its essential
                                    purpose. Some jurisdictions do not allow
                                    limits on certain liabilities so these
                                    limitations may not apply to you.
                                  </p>
                                  <br />
                                  <p><strong>Intellectual Property</strong></p>
                                  <br />
                                  <p>
                                    All intellectual property rights over the
                                    Plugin, Website, the Services, and/or the
                                    INK PPT Plus / INK PPT +, its design, and
                                    source code, and all content included in any
                                    of them (including without limitation text,
                                    images, animations, databases, graphics,
                                    logos, trademarks, icons, buttons, pictures,
                                    videos, sound recordings, etc.) belong or
                                    are licensed to the Contributors.
                                  </p>
                                  <br />
                                  <p>
                                    Except as expressly authorized under these
                                    Terms, the reproduction or distribution, as
                                    well as transformation, producing any
                                    derivative works of any kind, public
                                    communication, making available, extraction,
                                    reuse or any other use of the Website, the
                                    Services, the Plugin, Website Content or any
                                    of its parts, is strictly forbidden.
                                  </p>
                                  <br />
                                  <p>
                                    If you believe that any content infringes
                                    third party rights or does not comply with
                                    these Terms, you can report it to the
                                    Company as set forth in these Terms.
                                  </p>
                                  <br />
                                  <p><strong>License Agreement</strong></p>
                                  <br />
                                  <p>
                                    The Company to use our services under the
                                    terms of this Section. The Company and its
                                    Contributors reserve all rights over the INK
                                    PPT Plus / INK PPT + Content not expressly
                                    granted in this license to the User.
                                  </p>
                                  <br />
                                  <p>
                                    The User may only use contents within the
                                    INK PPT Plus / INK PPT + to create
                                    presentations, modify them, and share the
                                    editable presentation containing the Content
                                    with other individuals belonging to the same
                                    team or organization as the User and who are
                                    collaborating with the User in the
                                    presentation’s creation and/or modification
                                    <strong>(“User’s Team“)</strong>. Therefore,
                                    the User may only share an editable format
                                    of the website Content with the User’s Team
                                    and only to collaborate in the creation or
                                    modification of the presentation.
                                  </p>
                                  <br />
                                  <p>
                                    The User is only allowed to share Plugin,
                                    Website Content to third parties outside of
                                    the User’s Team in a non-editable format,
                                    ensuring always that neither the User, the
                                    User’s Team nor the third-party recipient
                                    are sublicensing, reselling or distributing
                                    Plugin, Website Content in any manner not
                                    expressly provided for in these Terms.
                                    Additionally, the User shall, at all times
                                    and whether sharing with the User’s Team or
                                    third parties, ensure that the creators or
                                    holders of rights over the original content
                                    included as part of the website Content
                                    (e.g. icons, vectors, photos, etc.) are duly
                                    credited in all copies of the presentation.
                                    In particular, the User undertakes not to
                                    remove in any editable or non-editable
                                    format of a presentation, any crediting
                                    section which may have been generated by the
                                    Plugin, Website, unless the User acquires a
                                    license allowing him/her/it to omit such
                                    acknowledgement. The Plugin, Website might
                                    contain further indications about the
                                    crediting obligations the User must comply
                                    with and information about which license
                                    allows them to remove such acknowledgement.
                                  </p>
                                  <br />
                                  <p>
                                    Under no circumstances shall the User or the
                                    User’s Team be allowed to sublicense,
                                    resell, distribute or allow any third
                                    parties to use the contents within the
                                    Plugin, Website in any other way without the
                                    Company’s prior written consent.
                                  </p>
                                  <br />
                                  <p>
                                    The User rights under this Section will end
                                    automatically without any notice if the User
                                    breaches any of the Terms. In case of
                                    termination of the rights hereunder, the
                                    User shall cease using content in the
                                    Plugin, Website and will destroy every copy,
                                    whether total or partial, thereof.
                                  </p>
                                  <br />
                                  <p>
                                    Likewise, in respect of collection and
                                    processing of personal data, the Privacy
                                    Policy will apply.
                                  </p>
                                  <br />
                                  <p>
                                    <strong
                                      >Changes and Closure of the
                                      Website</strong
                                    >
                                  </p>
                                  <br />
                                  <p>
                                    The Company may, at any moment, and without
                                    incurring in any responsibility towards the
                                    User, modify the content of the Plugin,
                                    Website or the Services, limit or modify the
                                    conditions or cease to provide some or all
                                    the Services and features available or
                                    deactivate and delete all or some of the
                                    User accounts and their corresponding
                                    information. However, the Company will
                                    comply with its obligations regarding the
                                    keeping of records in relation to certain
                                    transactions for the relevant period as
                                    provided by applicable law.
                                  </p>
                                  <br />
                                  <p><strong>Miscellaneous.</strong></p>
                                  <br />
                                  <p>
                                    The use of the Plugin, Website and the
                                    Services and the interpretation and
                                    application of these Terms shall be governed
                                    by Indian Law. The parties expressly waive
                                    any other applicable jurisdiction. If any
                                    provision in these Terms is declared to be
                                    invalid or unenforceable, it shall be
                                    substituted or deemed as it had not been
                                    included. The remaining provisions in these
                                    Terms shall not be affected in any way.
                                  </p>
                                  <br />
                                  <p>
                                    You may contact the Company for any query on
                                    Plugin, Website at
                                    <strong>
                                      https://inkppt.com/ink-ppt-plus-add-in-support/</strong
                                    >
                                  </p>
                                  <br />
                                  <p></p>
                                  <br />
                                </div>
                                <br />
                                <button
                                  class="btn btn-sm btn-neutral"
                                  style="
                                    size: 30px;
                                    margin-bottom: 15px;
                                    margin-left: auto;
                                    margin-right: auto;
                                  "
                                  @click="conditionModalRoute"
                                >
                                  I Agree
                                </button>
                              </div>
                            </div>
                            <!-- </div> -->
                          </div>
                        </div>
                      </transition>
                    </div>

                    <input
                      type="button"
                      name="previous"
                      class="previous action-button-previous"
                      value="Previous"
                    />
                    <input
                      type="button"
                      name="next"
                      class="next action-button"
                      value="Next Step"
                    />
                  </fieldset>
                  <fieldset>
                    <div class="form-card">
                      <div class="row">
                        <div class="card" style="width: 33%">
                          <div class="card-body text-center">
                            <h4 class="card-title">STARTER</h4>
                            <p class="card-text">
                            Price : INR 2000 <br/>
                            Members: 3<br/> 
                            Assets: 500                            
                              </p>
                            <input
                              type="button"
                              @click="setPlan(200000, 3)"
                              name="make_payment"
                              class="action-button"
                              value="Select"
                            />
                          </div>
                        </div>
                        <div class="card" style="width: 33%">
                          <div class="card-body text-center">
                            <h4 class="card-title">ADVANCED</h4>
                            <p class="card-text">
                            Price : INR 5000 <br/>
                            Members:  10 <br/>  
                             Assets: 2000                           
                              </p>
                            <input
                              type="button"
                               @click="setPlan(500000, 10)"
                              name="make_payment"
                              class="action-button"
                              value="Select"
                            />
                          </div>
                        </div>
                        <div class="card" style="width: 33%">
                          <div class="card-body text-center">
                            <h4 class="card-title">BUSINESS</h4>
                            <p class="card-text">
                            Price : INR 10000 <br/>
                            Members: 30 <br/> 
                             Assets: 5000                             
                              </p>
                            <input
                              type="button"
                               @click="setPlan(1000000, 30)"
                              name="make_payment"
                              class="action-button"
                              value="Select"
                            />
                          </div>
                        </div>                       
                      </div>
                    </div>
                    <input
                      type="button"
                      name="previous"
                      class="previous action-button-previous"
                      value="Previous"
                    />
                    <input
                      id="registerButton"
                      type="button"
                      name="make_payment"
                      class="next action-button"
                      @click="registerTenant()"
                      value="Confirm"
                      :disabled='confirmButton'
                    />
                  </fieldset>
                  <fieldset>
                    <button @click="initiatePayment" v-if="tenantRegistered == 'Tenant Registered'" class="action-button">Pay</button>

                    <div class="form-card" v-else>
                      <h2 class="fs-title text-center">{{tenantRegistered}}</h2> <br><br>
                                   <input
                      type="button"
                      name="previous"
                      class="previous action-button-previous"
                      value="Previous"
                    />          
                                            
                    </div>
                    <div class="form-card">
                      <!-- <h2 class="fs-title text-center">Success !</h2> <br><br>
                                            <div class="row justify-content-center">
                                                <div class="col-3"> <img src="https://img.icons8.com/color/96/000000/ok--v2.png" class="fit-image"> </div>
                                            </div> <br><br>
                                            <div class="row justify-content-center">
                                                <div class="col-7 text-center">
                                                    <h5>You Have Successfully Signed Up <a href="" @click="SignIn">LogIn Now </a></h5>
                                                </div>
                                            </div> -->
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="https://checkout.razorpay.com/v1/checkout.js"></script>
<script src="https://unpkg.com/axios/dist/axios.min.js"></script>


<script>
import $ from "jquery";
import Navbar from "@/components/Navbar.vue";
import common from "../common/index";
import Preloader from "./Preloader.vue";
// import axios from "axios";
export default {
  name: "SignUpTenant",
  components: {
    Navbar,
    Preloader,
  },
  data() {
    return {
      tenantRegistered : "",
      confirmButton: true,
      pageLoadingUsers: false,
      conditionModal: false,
      signUp: {
        //   userId: "",
        //   lastName: "",
        //  tenantId: "",
        emailErrMsg: "",
        userNameErrMsg:"",
        organizationErrMsg:"",
        email: "",
        username: "",
        password: "",
        contact: "",
        country: "",
        address: "",
        pin: "",
        organization: "",
        gst: "",
        notification: "",
        marketingPromotion: "",
        systemUpdate: "",
        amount: "",
        allowedUsers: "",
        pluginValidationDate: "",
        orderId: "",
        users: "",
        pluginCode: null,
        userId: null,
      },
    };
  },

  methods: {
     acceptNumber() {
        var x = this.signUp.contact.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  this.signUp.contact = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
    checkEmail() {
      if (
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.signUp.email)
      ) {
        this.signUp.emailErrMsg = "Please enter a valid email address";
      } else {
        this.$store
          .dispatch("checkEmail", this.signUp.email)
          .then((response) => {
            if (response === "mail already exists") {
              this.signUp.emailErrMsg = "Email Already Exists";
            } else {
              this.signUp.emailErrMsg = "";
            }
          });
      }
    },
    checkUsername(){
         this.$store
          .dispatch("checkUserName", this.signUp.username)
          .then((response) => {
            if (response === "mail already exists") {
              this.signUp.userNameErrMsg = "UserName Already Exists";
            } else {
              this.signUp.userNameErrMsg = "";
            }
          });
    },
    checkOrganization(){
             this.$store
          .dispatch("checkOrganization", this.signUp.organization)
          .then((response) => {
            if (response === "mail already exists") {
              this.signUp.organizationErrMsg = "Organization Already Exists";
            } else {
              this.signUp.organizationErrMsg = "";
            }
          });
    },
    pushToSignIn() {
      this.$router.push("/");
    },
    initiatePayment(e) {
      e.preventDefault();
      var options = {
       // key: "rzp_test_znlYveFkZUrmqJ", // Yogesh Account
       key: "rzp_test_i3TlGUyn30b2t7", // Aayush Account
        amount: this.signUp.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "Acme Corp",
        description: "Test Transaction",
        image: "https://example.com/your_logo",
        order_id: this.signUp.orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        callback_url: "http://localhost:8080/#/SignUpTenant",
        prefill: {
          name: this.signUp.username,
          email: this.signUp.email,
          contact: this.signUp.email,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
        handler: (response) => {
          //   var generated_signature = hmac_sha256(order_id + "|" + razorpay_payment_id, secret);
          //   if (generated_signature == response.razorpay_signature) {
          //       console.log("payment is successful");
          //         }
          console.log(response);
          this.pageLoadingUsers = true;
          const data = {
            id: "1",
            paymentId: response.razorpay_payment_id,
            orderId: response.razorpay_order_id,
            paymentSignature: response.razorpay_signature,
            paymentTime: this.getDateTime(0),
          };
          this.$store.dispatch("updatePayment", data).then((response) => {
            console.log(response);
            this.$router.push("/");
            this.$alertify.success("Payment SuccessFull");
          });
        },
      };
      var rzp1 = new Razorpay(options);
      rzp1.open();
    },
    getDateTime(year) {
      var now = new Date();
      var year = now.getFullYear() + year;
      var month = now.getMonth() + 1;
      var day = now.getDate();
      var hour = now.getHours();
      var minute = now.getMinutes();
      var second = now.getSeconds();
      if (month.toString().length == 1) {
        month = "0" + month;
      }
      if (day.toString().length == 1) {
        day = "0" + day;
      }
      if (hour.toString().length == 1) {
        hour = "0" + hour;
      }
      if (minute.toString().length == 1) {
        minute = "0" + minute;
      }
      if (second.toString().length == 1) {
        second = "0" + second;
      }
      var dateTime =
        year +
        "/" +
        month +
        "/" +
        day +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second;
      return dateTime;
    },
    setPlan(amount, admin) {
      this.signUp.amount = amount;
      this.signUp.users = admin;
      this.signUp.pluginValidationDate = this.getDateTime(1);
      this.confirmButton = false;

    },
    registerTenant() {
      if( !this.signUp.emailErrMsg == ''){
             this.$alertify.error("Email is Not Valid");
      }
      else {
      if (
        this.signUp.email == undefined ||
        this.signUp.username == undefined ||
        this.signUp.password == undefined ||
        this.signUp.contact == undefined ||
        this.signUp.country == undefined ||
        this.signUp.address == undefined ||
        this.signUp.pin == undefined ||
        this.signUp.organization == undefined ||
        this.signUp.gst == undefined
      ) {
        this.$alertify.error("Please fill all the required fields");
      }
      //    this.signUp.username = localStorage.getItem("username");
      else {
        this.pageLoadingUsers = true;
        this.$store
          .dispatch("registerNewTenant", this.signUp)
          .then((response) => {
            this.tenantRegistered = response.status;
            if (response.status === "Tenant Registered") {
               this.pageLoadingUsers = false;
              this.signUp.orderId = response.orderId;
              localStorage.setItem("orderId", this.signUp.orderId);
              this.$alertify.success("New Tenant Registered");
              this.$store.dispatch("sendCreds", this.signUp).then((res) => {
            });         
            }
            
            else if (response.status === "This Tenant Already Exists") {
              this.pageLoadingUsers = false;
              this.$alertify.error("This Tenant Already Exists!");
              setTimeout(() => {
                this.$router.push("/");
              }, 2000);
            }  else if (response.status === "plugin code expired") {
              this.pageLoadingUsers = false;
              this.$alertify.error("This plugin code has been expired");
            } else if (response.status === "maximum limit reached") {
              this.pageLoadingUsers = false;
              this.$alertify.error(
                "User Limit has reached. Please write to admin on support@inkppt.com"
              );
            } else if (response.status === "invalid plugin code") {
              this.pageLoadingUsers = false;
              this.$alertify.error("Plugin code is invalid");
            } else if (response.status === "No Match Found") {
              this.pageLoadingUsers = false;
              this.$alertify.error("Plugin Code is Invalid");
            } else {
              
              this.$alertify.error("Registration Failed");
              this.$store.dispatch("sendCreds", this.signUp).then((res) => {
                console.log(res);
              });
            }
          });
      }
      }
    },
    SignIn() {
      this.$router.push("SignUpTenant");
    },
    conditionModalRoute(e) {
      e.preventDefault();
       this.conditionModal = !this.conditionModal;
     },
  },

  mounted: function () {
    console.log("value", this.$router.currentRoute.params);
    this.signUp.email = this.$router.currentRoute.params.email;
    this.signUp.username = this.$router.currentRoute.params.username;
    this.signUp.password = this.$router.currentRoute.params.password;
    this.signUp.contact = this.$router.currentRoute.params.contact;
    this.signUp.country = this.$router.currentRoute.params.country;
    this.signUp.address = this.$router.currentRoute.params.address;
    this.signUp.pin = this.$router.currentRoute.params.pin;
    this.signUp.organization = this.$router.currentRoute.params.name;
    this.signUp.gst = this.$router.currentRoute.params.gst;
    this.signUp.notification = this.$router.currentRoute.params.notification;
    this.signUp.marketingPromotion =
      this.$router.currentRoute.params.marketingPromotion;
    this.signUp.systemUpdate = this.$router.currentRoute.params.systemUpdate;
    this.signUp.pluginCode = this.$router.currentRoute.params.pluginCode;
    this.signUp.userId = localStorage.getItem("userId");
    $(document).ready(function () {
      var currentFs, nextFs, previousFs;
      var opacity;
     
     $(".next").click(function () {      
        console.log($(this).parent());
        currentFs = $(this).parent();
        nextFs = $(this).parent().next();
        //Add Class Active
        $("#progressbar li").eq($("fieldset").index(nextFs)).addClass("active");
        //show the next fieldset
        nextFs.show();
        //hide the current fieldset with style
        currentFs.animate(
          { opacity: 0 },
          {
            step: function (now) {
              // for making fielset appear animation
              opacity = 1 - now;

              currentFs.css({
                display: "none",
                position: "relative",
              });
              nextFs.css({ opacity: opacity });
            },
            duration: 600,
          }
        );
      });    
      $(".previous").click(function () {
        currentFs = $(this).parent();
        previousFs = $(this).parent().prev();

        //Remove class active
        $("#progressbar li")
          .eq($("fieldset").index(currentFs))
          .removeClass("active");

        //show the previous fieldset
        previousFs.show();

        //hide the current fieldset with style
        currentFs.animate(
          { opacity: 0 },
          {
            step: function (now) {
              // for making fielset appear animation
              opacity = 1 - now;

              currentFs.css({
                display: "none",
                position: "relative",
              });
              previousFs.css({ opacity: opacity });
            },
            duration: 600,
          }
        );
      });

      $(".radio-group .radio").click(function () {
        $(this).parent().find(".radio").removeClass("selected");
        $(this).addClass("selected");
      });

      $(".submit").click(function () {
        return false;
      });
    });

  },
  created: function () {
    console.log("sign up tenant creatd called");
  },
};
</script>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* display: table; */
  display: flex;
  transition: opacity 0.3s ease;
  overflow-y: scroll;
}
/* #grad1 {
    background-color: #9C27B0;
    background-image: linear-gradient(120deg, #FF4081, #81D4FA)
} */
.signup-wizard {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  padding: 24px;
}
#msform {
  text-align: center;
  position: relative;
  margin-top: 20px;
}

#msform fieldset .form-card {
  background: white;
  border: 0 none;
  border-radius: 0px;
  /* box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2); */
  padding: 20px 40px 30px 40px;
  box-sizing: border-box;
  width: 94%;
  margin: 0 3% 20px 3%;
  position: relative;
}

#msform fieldset {
  background: white;
  border: 0 none;
  border-radius: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding-bottom: 20px;
  position: relative;
}

#msform fieldset:not(:first-of-type) {
  display: none;
}

#msform fieldset .form-card {
  text-align: left;
  color: #9e9e9e;
}

#msform input,
#msform textarea {
  padding: 0px 8px 4px 8px;
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0px;
  margin-bottom: 0px;
  margin-top: 25px;
  width: 100%;
  box-sizing: border-box;
  font-family: montserrat;
  color: #2c3e50;
  font-size: 16px;
  letter-spacing: 1px;
}

#msform input:focus,
#msform textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: none;
  font-weight: bold;
  border-bottom: 2px solid #00b0ac;
  outline-width: 0;
}

#msform .action-button {
  width: 100px;
  background: #00b0ac;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
}

#msform .action-button:hover,
#msform .action-button:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 3px #00b0ac;
}
#msform .action-button:disabled {
  background: #70a3a2;
  box-shadow: none;
  cursor: none;
}

#msform .action-button-previous {
  width: 100px;
  background: #616161;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
}

#msform .action-button-previous:hover,
#msform .action-button-previous:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 3px #616161;
}

select.list-dt {
  border: none;
  outline: 0;
  border-bottom: 1px solid #ccc;
  padding: 2px 5px 3px 5px;
  margin: 2px;
}

select.list-dt:focus {
  border-bottom: 2px solid #00b0ac;
}

.card {
  z-index: 0;
  border: none;
  border-radius: 0.5rem;
  position: relative;
}

.fs-title {
  font-size: 25px;
  color: #2c3e50;
  margin-bottom: 10px;
  font-weight: bold;
  text-align: left;
}

#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  color: lightgrey;
  margin-left: 10%;
  text-align: left;
}

#progressbar .active {
  color: #000000;
}

#progressbar li {
  list-style-type: none;
  font-size: 12px;
  width: 20%;
  float: left;
  position: relative;
}

#progressbar #account:before {
  font-family: FontAwesome;
  content: "\f023";
  text-align: center;
}

#progressbar #personal:before {
  font-family: FontAwesome;
  content: "\f007";
  text-align: center;
}

#progressbar #payment:before {
  font-family: FontAwesome;
  content: "\f09d";
  text-align: center;
}

#progressbar #confirm:before {
  font-family: FontAwesome;
  content: "\f00c";
  text-align: center;
}

#progressbar li:before {
  width: 50px;
  height: 50px;
  line-height: 45px;
  display: block;
  font-size: 18px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  /* margin: 0 auto 10px auto;
    padding: 2px */
}

#progressbar li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: lightgray;
  position: absolute;
  left: 0;
  top: 25px;
  z-index: -1;
}

#progressbar li:last-child:after {
  content: "";
  width: 0% !important;
  height: 2px;
  background: lightgray;
  position: absolute;
  left: 0;
  top: 25px;
  z-index: -1;
}

#progressbar li.active:before,
#progressbar li.active:after {
  background: #00b0ac;
}

.radio-group {
  position: relative;
  margin-bottom: 25px;
}

.radio {
  display: inline-block;
  width: 204;
  height: 104;
  border-radius: 0;
  background: lightblue;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  cursor: pointer;
  margin: 8px 2px;
}

.radio:hover {
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.3);
}

.radio.selected {
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);
}

.fit-image {
  width: 100%;
  object-fit: cover;
}
</style>
