<template>
  <div class="container">
      <Navbar />
    <div>
      <div class="container" style="margin-left: 5px;">
        <div class="row" style="margin-left: 5px; justify-content: center;">
          <div class="col emailOtpVer">
            <form action="" method="post" id="fileForm" role="form">
                <header class="header-part">
                    <h2 style="color: #4C156A; margin-bottom: 0px; margin-left: 30%">
                    Enter otp
                    </h2>
                </header>
              <fieldset>
                <div class="form-group">
                  <label for="firstname">Enter otp: </label>
                  <input
                    class="form-control"
                    type="text"
                    name="otp"
                    @keypress="onlyNumber"
                    maxlength="6"
                    required
                  />
                </div>
                <small style="color: #4C156A; margin-left: 5px;"
                  >An OTP has been sent to your mail Id, Kindly enter the same
                  in the above box</small
                >
                <div class="load-more-btn text-center mt-2" @click="submitOtp">
                  <a>Submit</a>
                </div>
                <div class="load-more-btn text-center mt-3" @click="cancel">
                  <a>Cancel</a>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </div>
   </div>
</template>
<script>
import Navbar from '@/components/Navbar.vue'
export default {
  name: 'EmailOtpVerification',
  components:{
      Navbar
  },
  methods:{
      cancel() {
          this.$router.push("/SignUpTenant");
      }
  }
}
</script>
<style scoped>
.load-more-btn{
 background: #00B0AC;
 padding-top: 0px !important;
 padding: 2px 8px;
}
.load-more-btn a {
  max-width: 300px !important;
  line-height: 35px;
  
}
.load-more-btn:hover {
  background: #4c156a;
  color: #fff;
}
.form-control {
  width: 100%;
}
label small {
  color: #678 !important;
}
span.req {
  color: maroon;
  font-size: 112%;
}
.header-title h3 {
  text-align: center;
  margin-top: 7px;
}
.emailOtpVer{
  max-width:30%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  padding: 24px;
}
</style>
