


<template>
    <div>
  <h1>My Photo Albums App</h1>
    <div id="app"></div>
    </div>
</template>
 <script src="https://sdk.amazonaws.com/js/aws-sdk-2.1111.0.min.js"></script>
    <script src="./imgUpload1.js"></script>
    <script>
    var albumBucketName = "inkppt-beta-frontend";
var bucketRegion = "ap-south-1";
var IdentityPoolId = "ap-south-1:60b1d5df-623e-4027-ba2b-1f8d7acc00aa";

AWS.config.update({
  accessKeyId: "AKIARVHHZRJPMHZ6P3OM",
  secretAccessKey: "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",
  region: bucketRegion,
  AWS_SDK_LOAD_CONFIG : 1,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: IdentityPoolId,
  }),
});

var s3 = new AWS.S3({
  apiVersion: "2006-03-01",
  params: { Bucket: albumBucketName }
});
    </script>
    
<script>
import Preloader from "./Preloader.vue";
import Navbar from '@/components/Navbar.vue';
import AWS from 'aws-sdk';
export default {
  name: "ImgUpload",
  components:{
     Navbar,
      Preloader
  },
  data() {
    return {
      loginUser: {
        email: "",
        password: ""
      },
      analytics: {
        EventCategory: "",
        ActionName: "",
        Label: "",
        Cid: ""
      },
      pageLoading: false,
      loggedIn: "",
      // eslint-disable-next-line
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
    };
  },
  methods: {
    listAlbums() {
       var albumBucketName = "inkppt-beta-frontend";
var bucketRegion = "ap-south-1";
var IdentityPoolId = "ap-south-1:60b1d5df-623e-4027-ba2b-1f8d7acc00aa";

AWS.config.update({
  // accessKeyId: "AKIARVHHZRJPMHZ6P3OM",
  // secretAccessKey: "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",
  region: bucketRegion,
  AWS_SDK_LOAD_CONFIG : 1,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: IdentityPoolId,
  }),
});

var s3 = new AWS.S3({
  apiVersion: "2006-03-01",
  params: { Bucket: albumBucketName }
});
  s3.listObjects({ Delimiter: "Images/Assets/Buckets/" }, function (err, data) {
    if (err) {
      return alert("There was an error listing your albums: " + err.message);
    } else {
      var albums = data.CommonPrefixes.map(function (commonPrefix) {
        var prefix = commonPrefix.Prefix;
        var albumName = decodeURIComponent(prefix.replace("/", ""));
        return ([
          "<li>",
          "<span onclick=\"deleteAlbum('" + albumName + "')\">X</span>",
          "<span onclick=\"viewAlbum('" + albumName + "')\">",
          albumName,
          "</span>",
          "</li>",
        ]);
      });
      var message = albums.length
        ? ([
            "<p>Click on an album name to view it.</p>",
            "<p>Click on the X to delete the album.</p>",
          ])
        : "<p>You do not have any albums. Please Create album.";
      var htmlTemplate = [
        "<h2>Albums</h2>",
        message,
        "<ul>",
        (albums),
        "</ul>",
        "<button onclick=\"createAlbum(prompt('Enter Album Name:'))\">",
        "Create New Album",
        "</button>",
      ];
      document.getElementById("app").innerHTML = (htmlTemplate);
    }
  });
},
login() {
      // this.analytics.EventCategory = "Login";
      // this.analytics.ActionName = "Login_Button_Clicked";
      // this.analytics.Label = this.loginUser.email;
      // this.analytics.Cid = this.loginUser.email;
      // this.$store.dispatch("googleAnalytics", this.analytics);
      if (this.loginUser.email === "" || this.loginUser.password === "") {
        this.$alertify.error("Please fill the required fields");
      }
      //  else if (!this.reg.test(this.loginUser.email)) {
      //   this.$alertify.error("Please enter a valid email address");
      // }
       else {
        this.pageLoading = true;
        this.$store.dispatch("login", this.loginUser).then(response => {
          console.log(response);
          if (response[0] === "Invalid email or Password") {
            console.log("Status Invalid");
            this.pageLoading = false;
            this.$alertify.error(
              "email or Password is Incorrect! Please Try again"
            );
          } else if (response[0] === "Email and password matched") {
            //  common.setUserStatus();

            localStorage.setItem("UserName", response[1]);
            localStorage.setItem("tenantId", response[2]);
             localStorage.setItem("userId", response[3]);
           this.$alertify.success(
              "Login Successfully"
            );
            this.$router.push("/Dashboard");
          }
        });
      }
    },
    registerHere() {
      this.analytics.EventCategory = "Login";
      this.analytics.ActionName = "Register_Button_Clicked";
      this.analytics.Label = this.loginUser.email;
      this.analytics.Cid = this.loginUser.email;
      this.$store.dispatch("googleAnalytics", this.analytics);
      this.$router.push("/SignUpTenant");
    },
    forgotPass() {
      this.analytics.EventCategory = "Login";
      this.analytics.ActionName = "ForgotPassword_Button_Clicked";
      this.analytics.Label = this.loginUser.email;
      this.analytics.Cid = this.loginUser.email;
      this.$store.dispatch("googleAnalytics", this.analytics);
      this.$router.push("/ForgotPassword");
    }
  },
    created: function () {
      this.listAlbums();   
  },
};




</script>


