import Vue from 'vue'
import Router from 'vue-router'
import SignIn from '@/components/SignIn'
import SignUpTenant from '@/components/SignUpTenant'
import ImgUpload from "@/components/ImgUpload";
import ImgUpload2 from "@/components/ImgUpload2";
import ForgotPassword from '@/components/ForgotPassword'
import EmailOtpVerification from '@/components/EmailOtpVerification'
import Dashboard from '@/components/Dashboard'
Vue.use(Router)

export default new Router({
  routes: [
    {
      path: "/",
      name: "SignIn",
      component: SignIn,
    },
    {
      path: "/ImgUpload",
      name: "ImgUpload",
      component: ImgUpload,
    },
    {
      path: "/ImgUpload2",
      name: "ImgUpload2",
      component: ImgUpload2,
    },
    {
      path: "/Dashboard",
      name: "Dashboard",
      component: Dashboard,
    },
    {
      path: "/ForgotPassword",
      name: "ForgotPassword",
      component: ForgotPassword,
    },
    {
      path: "/EmailOtpVerification",
      name: "EmailOtpVerification",
      component: EmailOtpVerification,
    },
    {
      path: "/SignUpTenant",
      name: "SignUpTenant",
      component: SignUpTenant,
    },
    {
      path: "/SignUpTenant/:email/:username/:password/:contact/:country/:address/:pin/:name/:gst/:notification/:marketingPromotion/:systemUpdate",
      name: "SignUpTenantUpdate",
      component: SignUpTenant,
    },
  ],
});
