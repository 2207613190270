import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import common from "../common/index";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    loginUser: {},
    UserData: {},
    registerUser: {},
    registerMember: {},
    updatedDetails: {},
    checkUser: {},
    otp: {},
    downloadpptx: {},
    analytics: {},
    tenantId: {},
    userId: {},
    webregisterUser: {},
    registerUserId: {},
    registerMemberId: {},
    childbucketForm: {},
    bucketId: {},
    parentBucketForm: {},
  },
  getters: {
    loginUserGetter: (state) => state.loginUser,
    UserDataGetter: (state) => state.UserData,
    registerUserGetter: (state) => state.registerUser,
    registerMemberGetter: (state) => state.registerMember,
    updatedDetailsGetter: (state) => state.updatedDetails,
    checkUserGetter: (state) => state.checkUser,
    otpGetter: (state) => state.otp,
    downloadpptxGetter: (state) => state.downloadpptx,
    analyticsGetter: (state) => state.analytics,
    tenantIdGetter: (state) => state.tenantId,
    webregisterUserGetter: (state) => state.webregisterUser,
    childbucketFormGetter: (state) => state.childbucketForm,
    buckedIdGetter: (state) => state.bucketId,
    webregisterUserIdGetter: (state) => state.webregisterUserId,
    parentBucketFormGetter: (state) => state.parentBucketForm,
  },
  //  mutations: {
  //   loginUserReceived(state, loginUser) {
  //     state.loginUser = loginUser
  //   },
  //   registerUserReceived(state, registerUser) {
  //     state.registerUser = registerUser
  //   }
  // },
  actions: {
    updatePayment: (context, data) => {
      return new Promise((resolve) => {
        axios
          .post(
            common.awsServerlesslamdaapi +
              "RegisterUser/updatepayment?id=" +
              data.id +
              "&paymentId=" +
              data.paymentId +
              "&orderId=" +
              data.orderId +
              "&paymentSignature=" +
              data.paymentSignature +
              "&paymentTime=" +
              data.paymentTime
          )
          .then(function (response) {
            resolve(response.data);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    registerNewTenant: (context, signUp) => {
      return new Promise((resolve) => {
        axios
          .post(
            common.awsServerlesslamdaapi +
              "RegisterUser/registernewtenant?username=" +
              signUp.username +
              "&email=" +
              signUp.email +
              "&password=" +
              signUp.password +
              "&contact=" +
              signUp.contact +
              "&country=" +
              signUp.country +
              "&address=" +
              signUp.address +
              "&pin=" +
              signUp.pin +
              "&organization=" +
              signUp.organization +
              "&gst=" +
              signUp.gst +
              "&notification=" +
              signUp.notification +
              "&marketingPromotion=" +
              signUp.marketingPromotion +
              "&systemUpdate=" +
              signUp.systemUpdate +
              "&amount=" +
              signUp.amount +
              "&users=" +
              signUp.users +
              "&pluginValidationDate=" +
              signUp.pluginValidationDate +
              "&pluginCode=" +
              signUp.pluginCode +
              "&userId=" +
              signUp.userId
          )
          .then(function (response) {
            resolve(response.data);
          })
          .catch((err) => {
            resolve(err.response.data);
          });
      });
    },
    checkEmail: (context, email) => {
      return new Promise((resolve) => {
        axios
          .post(
            common.awsServerlesslamdaapi +
              "RegisterUser/checkemail?email=" +
              email
          )
          .then(function (response) {
            resolve(response.data);
          })
          .catch((err) => {
            resolve(err.response.data);
          });
      });
    },
    checkUserName: (context, userName) => {
      return new Promise((resolve) => {
        axios
          .post(
            common.awsServerlesslamdaapi +
              "RegisterUser/checkusername?username=" +
              userName
          )
          .then(function (response) {
            resolve(response.data);
          })
          .catch((err) => {
            resolve(err.response.data);
          });
      });
    },
    checkOrganization: (context, organizationName) => {
      return new Promise((resolve) => {
        axios
          .post(
            common.awsServerlesslamdaapi +
              "RegisterUser/checkorganizationname?organizationName=" +
              organizationName
          )
          .then(function (response) {
            resolve(response.data);
          })
          .catch((err) => {
            resolve(err.response.data);
          });
      });
    },
    login: (context, loginUser) => {
      return new Promise((resolve) => {
        axios
          .get(
            common.awsServerlesslamdaapi +
              "User/adminlogin?email=" +
              loginUser.email +
              "&password=" +
              loginUser.password
          )
          .then(function (response) {
            resolve(response.data);
            console.log(response.data);
          })
          .catch((err) => {
            resolve(err.response.data);
          });
      });
    },
    // show users on web //
    signUpInfo: (context, data) => {
      return new Promise((resolve) => {
        axios
          .get(
            common.awsServerlesslamdaapi +
              "User/signUpInfo?userId=" +
              data.userId
          )
          .then(function (response) {
            resolve(response);
            console.log(response.data);
          })
          .catch((err) => {
            resolve(err.response.data);
            console.log("Getting Error");
          });
      });
    },
    // show users on web //
    tenantInfo: (context, tenantId) => {
      return new Promise((resolve) => {
        axios
          .get(
            common.awsServerlesslamdaapi +
              "User/tenantInfo?tenantId=" +
              tenantId
          )
          .then(function (response) {
            resolve(response);
            console.log(response.data);
          })
          .catch((err) => {
            resolve(err.response.data);
            console.log("Getting Error");
          });
      });
    },
    // show users on web //
    registerUsers: (context, tenantId) => {
      return new Promise((resolve) => {
        axios
          .get(
            common.awsServerlesslamdaapi +
              "User/getregisterusers?tenantId=" +
              tenantId
          )
          .then(function (response) {
            resolve(response);
            console.log(response.data);
          })
          .catch((err) => {
            resolve(err.response.data);
            console.log("Getting Error");
          });
      });
    },
    // show admins on web //
    registerMembers: (context, tenantId) => {
      return new Promise((resolve) => {
        axios
          .get(
            common.awsServerlesslamdaapi +
              "User/getregistermembers?tenantId=" +
              tenantId
          )
          .then(function (response) {
            resolve(response);
            console.log(response.data);
          })
          .catch((err) => {
            resolve(err.response.data);
            console.log("Getting Error");
          });
      });
    },
    webregister: (context, registerUser) => {
      console.log(common.awsServerlesslamdaapi);
      return new Promise((resolve) => {
        axios
          .post(
            common.awsServerlesslamdaapi +
              "RegisterUser/webregister?firstName=" +
              registerUser.firstName +
              "&lastName=" +
              registerUser.lastName +
              "&email=" +
              registerUser.email +
              "&tenantId=" +
              registerUser.tenantId +
              "&password=" +
              registerUser.password
          )
          .then(function (response) {
            resolve(response.data);
          })
          .catch((err) => {
            resolve(err.response.data);
          });
      });
    },
    // add admin by web app
    registerAdmin: (context, registerMember) => {
      console.log(common.awsServerlesslamdaapi);
      return new Promise((resolve) => {
        axios
          .post(
            common.awsServerlesslamdaapi +
              "RegisterUser/registeradmin?username=" +
              registerMember.username +
              "&role=" +
              registerMember.role +
              "&tenantId=" +
              registerMember.tenantId +
              "&password=" +
              registerMember.password
          )
          .then(function (response) {
            resolve(response.data);
          })
          .catch((err) => {
            resolve(err.response.data);
          });
      });
    },
    updatewebregister: (context, registerUser) => {
      console.log(common.awsServerlesslamdaapi);
      return new Promise((resolve) => {
        axios
          .post(
            common.awsServerlesslamdaapi +
              "RegisterUser/updatewebregister?firstName=" +
              registerUser.firstName +
              "&lastName=" +
              registerUser.lastName +
              "&email=" +
              registerUser.email +
              "&tenantId=" +
              registerUser.tenantId +
              "&password=" +
              registerUser.password +
              "&userId=" +
              registerUser.userId
          )
          .then(function (response) {
            resolve(response.data);
          })
          .catch((err) => {
            resolve(err.response.data);
          });
      });
    },
    updatewebregistermember: (context, registerMember) => {
      console.log(common.awsServerlesslamdaapi);
      return new Promise((resolve) => {
        axios
          .post(
            common.awsServerlesslamdaapi +
              "RegisterUser/updatewebregistermember?username=" +
              registerMember.username +
              "&role=" +
              registerMember.role +
              "&tenantId=" +
              registerMember.tenantId +
              "&password=" +
              registerMember.password +
              "&Id=" +
              registerMember.Id
          )
          .then(function (response) {
            resolve(response.data);
          })
          .catch((err) => {
            resolve(err.response.data);
          });
      });
    },
    // delete user on web //
    deleteregisteruser: (context, registerUserId) => {
      return new Promise((resolve) => {
        axios
          .post(
            common.awsServerlesslamdaapi +
              "RegisterUser/deletewebregister?userId=" +
              registerUserId
          )
          .then(function (response) {
            resolve(response.data);
          })
          .catch((err) => {
            resolve(err.response.data);
          });
      });
    },
    // delete member on web //
    deleteregistermember: (context, registerId) => {
      return new Promise((resolve) => {
        axios
          .post(
            common.awsServerlesslamdaapi +
              "RegisterUser/deletewebregistermember?Id=" +
              registerId
          )
          .then(function (response) {
            resolve(response.data);
          })
          .catch((err) => {
            resolve(err.response.data);
          });
      });
    },
    deletebucket: (context, bucketId) => {
      return new Promise((resolve) => {
        axios
          .post(
            common.awsServerlesslamdaapi +
              "RegisterUser/deletebucket?bucketId=" +
              bucketId
          )
          .then(function (response) {
            resolve(response.data);
          })
          .catch((err) => {
            resolve(err.response.data);
          });
      });
    },
    updatePassword: (context, updatedDetails) => {
      console.log(common.awsServerlesslamdaapi);
      return new Promise((resolve) => {
        axios
          .post(
            common.awsServerlesslamdaapi +
              "Otp/updatepassword?email=" +
              updatedDetails.email +
              "&password=" +
              updatedDetails.password +
              "&confirmPassword=" +
              updatedDetails.confirmPassword
          )
          .then(function (response) {
            resolve(response.data);
          })
          .catch((err) => {
            resolve(err.response.data);
          });
      });
    },
    googleAnalytics: (context, analytics) => {
      return new Promise((resolve) => {
        axios
          .post(
            common.awsServerlesslamdaapi +
              "GoogleAnalytics/googleanalyticsapi?EventCategory=" +
              analytics.EventCategory +
              "&ActionName=" +
              analytics.ActionName +
              "&Label=" +
              analytics.Label +
              "&Cid=" +
              analytics.Cid
          )
          .then(function (response) {
            resolve(response.data);
            console.log(response);
          })
          .catch((err) => {
            resolve(err.response.data);
          });
      });
    },

    checkUser: (context, checkUser) => {
      console.log(common.awsServerlesslamdaapi);
      return new Promise((resolve) => {
        axios
          .post(
            common.awsServerlesslamdaapi +
              "UserCheck/checkUser?email=" +
              checkUser
          )
          .then(function (response) {
            resolve(response.data);
          })
          .catch((err) => {
            resolve(err.response.data);
          });
      });
    },
    sendCreds: (context, data) => {
      console.log(common.awsServerlesslamdaapi);
      return new Promise((resolve) => {
        axios
          .post(
            common.awsServerlesslamdaapi +
              "UserCheck/sendCred?email=" +
              data.email +
              "&password=" +
              data.password +
              "&username=" +
              data.username
          )
          .then(function (response) {
            resolve(response.data);
          })
          .catch((err) => {
            resolve(err.response.data);
          });
      });
    },
    oneTimePassword: (context, otp) => {
      return new Promise((resolve) => {
        axios
          .get(common.awsServerlesslamdaapi + "Otp/otp?otp=" + otp)
          .then(function (response) {
            resolve(response.data);
          })
          .catch((err) => {
            resolve(err.response.data);
          });
      });
    },
    downloadpptx: (context, filename) => {
      console.log(filename);
      return new Promise(() => {
        axios.get(
          common.awsServerlesslamdaapi +
            "UserCheck/downloadPptx?fileName=" +
            filename
        );
      });
    },
    addchildbuckets: (context, childbucketForm) => {
     
      return new Promise((resolve) => {
        axios
          .post(
            common.awsServerlesslamdaapi +
              "RegisterUser/addchildbucket?parentSelectedValue=" +
              childbucketForm.parentSelectedValue +
              "&bucketName=" +
              childbucketForm.bucketName +
              "&assetTypeSelected=" +
              childbucketForm.assetTypeSelected +
              "&viewStyleSelected=" +
              childbucketForm.viewStyleSelected +
              "&tenantId=" +
              childbucketForm.tenantId +
              "&bucketLogoName=" +
              childbucketForm.bucketLogoName 
          ).then(function (response) {
                 resolve(response.data);
                 console.log(response.data);
               })
               .catch((err) => {
                 resolve(err.response.data);
                 console.log(err.response.data);
               });
      });
    },   
    updatechildbuckets: (context, childbucketForm) => {
      console.log(common.awsServerlesslamdaapi);
      return new Promise((resolve) => {
        axios
          .post(
            common.awsServerlesslamdaapi +
              "RegisterUser/updatechildbucket?parentSelectedValue=" +
              childbucketForm.parentSelectedValue +
              "&childBucketName=" +
              childbucketForm.bucketName +
              "&assetTypeSelected=" +
              childbucketForm.assetTypeSelected +
              "&viewStyleSelected=" +
              childbucketForm.viewStyleSelected +
              "&bucketLogo=" +
              childbucketForm.bucketLogo +
              "&bucketStatus=" +
              childbucketForm.bucketStatus +
              "&bucketId=" +
              childbucketForm.bucketId +
              "&tenantId=" +
              childbucketForm.tenantId +
              "&logoName=" +
              childbucketForm.bucketLogoName
          )
          .then(function (response) {
            resolve(response.data);
          })
          .catch((err) => {
            resolve(err.response.data);
          });
      });
    },
    addparentbuckets: (context, parentBucketForm) => {
      console.log(common.awsServerlesslamdaapi);
      return new Promise((resolve) => {
        axios
          .post(
            common.awsServerlesslamdaapi +
              "RegisterUser/addparentbucket?parentBucketName=" +
              parentBucketForm.bucketName +
              "&bucketLogoName=" +
              parentBucketForm.bucketLogoName +
              "&tenantId=" +
              parentBucketForm.tenantId
          )
          .then(function (response) {
            resolve(response.data);
          })
          .catch((err) => {
            resolve(err.response.data);
          });
      });
    },   
    updateparentbuckets: (context, parentBucketForm) => {
      console.log(common.awsServerlesslamdaapi);
      return new Promise((resolve) => {
        axios
          .post(
            common.awsServerlesslamdaapi +
              "RegisterUser/updateparentbucket?parentBucketName=" +
              parentBucketForm.bucketName +
              "&bucketStatus=" +
              parentBucketForm.bucketStatus +
              "&bucketId=" +
              parentBucketForm.bucketId +
              "&tenantId=" +
              parentBucketForm.tenantId +
              "&bucketLogoName=" +
              parentBucketForm.bucketLogoName
          )
          .then(function (response) {
            resolve(response.data);
          })
          .catch((err) => {
            resolve(err.response.data);
          });
      });
    },
  },
});
export { store };
