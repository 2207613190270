<template>
    <div class="circle-loader"></div>
</template>

<script>
export default {
    name: 'Preloader'
}
</script>


<style>
body {
  margin: 0;
  padding: 0;
  background-color: #ffffff;
}

.circle-loader {
  position: absolute;
  z-index: 1;
  top: 250px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 15px solid rgba(180, 180, 180, 0.2);
  border-top: 15px solid #47ee4f;
  animation: animate 1.5s infinite linear;
}

@keyframes animate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

#Location
{
  position: absolute;
  right: 0;
  left: 0;
  bottom: -200px;
  display: block;
  width: 160px;
  text-align: center;
  color: #fff;
  font-size: 15px;
  text-decoration: none;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  padding: 10px;
  margin: 0 auto;
  background-color: rgb(23, 248, 79);
  border-radius: 2px;
  animation: showYtLink 1.5s ease 3s forwards;
}

@keyframes keyframe
{
  0%{ bottom: -200px; }
  100%{ bottom: 20px; }
}
</style>