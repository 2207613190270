/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
export default {
  insertImage: insertImage,
  insertPptx: insertPptx,
  setUserStatus: setUserStatus,
  getUserStatus: getUserStatus,
  removeUser: removeUser,
  insertSvg: insertSvg,
  downloadPptx: downloadPptx,
  // baseurl: 'https://localhost:44395/', // Localhost
  // baseurl: "https://dr8wyk5v81hjf.cloudfront.net/", // Production
  baseurl: "https://ddzz448hcy4m2.cloudfront.net/", // Testing
  // awsServerlesslamdaapi: "http://localhost:56629/api/" // Localhost
   awsServerlesslamdaapi: "https://gni1sj0ezl.execute-api.ap-south-1.amazonaws.com/Prod/api/" // Testing
  // awsServerlesslamdaapi: "https://mw9vhgco1h.execute-api.ap-south-1.amazonaws.com/Prod/api/" // Production
  // awsServerlesslamdaapi: "http://localhost:8082/api/" //IIS Server
};
function setUserStatus() {
  var status = Office.context.document.settings.set("userStatus", true);
  Office.context.document.settings.saveAsync(function(asyncResult) {
    if (asyncResult.status === Office.AsyncResultStatus.Failed) {
      console.log("Settings save failed. Error: " + asyncResult.error.message);
    } else {
      console.log("User Saved");
    }
  });
}
function getUserStatus() {
  var status = Office.context.document.settings.get("userStatus");
  console.log(status);
  return status;
}
function removeUser() {
  Office.context.document.settings.remove("userStatus");
  Office.context.document.settings.saveAsync(function(asyncResult) {
    if (asyncResult.status === Office.AsyncResultStatus.Failed) {
      console.log("Settings save failed. Error: " + asyncResult.error.message);
    } else {
      console.log("UserStatus removed");
    }
  });
}

function insertImage(fileName, type) {
  return new Promise(function(resolve, reject) {
    try {
      getbase64froms3bucket(fileName, type, function(data) {
        var base64 = data.Body.toString("base64");
        insertImageFromBase64String(base64);
        resolve(true);
      });
    } catch (error) {
      resolve(false);
    }
  });
}
function insertPptx(fileName, type) {
  getbase64froms3bucket(fileName, type, async function(data) {
    var base64 = data.Body.toString("base64");
    console.log(fileName);
    insertPptxFromBase64String(base64);
  });
}

function downloadPptx(fileName, type) {
  getbase64froms3bucket(fileName, type, async function(data) {
    var base64 = data.Body.toString("base64");

    PowerPoint.createPresentation(base64);
  });
}

async function insertPptxFromBase64String(chosenFileBase64) {
  Office.context.document.getSelectedDataAsync(
    Office.CoercionType.SlideRange,
    function(asyncResult) {
      try {
        if (asyncResult.status === Office.AsyncResultStatus.Failed) {
          console.log(asyncResult.error.message);
        } else {
          const selectedSlideID = asyncResult.value.slides[0].id;
          PowerPoint.run(async function(context) {
            context.presentation.insertSlidesFromBase64(chosenFileBase64, {
              formatting: "UseDestinationTheme",
              targetSlideId: selectedSlideID + "#"
            });
            await context.sync();
          });
        }
      } catch (error) {
        reject(console.log(error));
      }
    }
  );
}

function getbase64froms3bucket(filename, type, callback) {
  AWS.config.update({
    accessKeyId: "AKIARVHHZRJPJ244U4EZ",
    secretAccessKey: "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"
  });
  AWS.config.region = "ap-south-1";
  var bucket = new AWS.S3({ params: { Bucket: "inkppt-beta-frontend" } });
  bucket.getObject({ Key: "Images/Assets/" + type + "/" + filename }, function(
    error,
    data
  ) {
    callback(data);
  });
}

function insertImageFromBase64String(image) {
  Office.context.document.setSelectedDataAsync(
    image,
    {
      coercionType: Office.CoercionType.Image
    },
    function(asyncResult) {
      if (asyncResult.status === Office.AsyncResultStatus.Failed) {
        icondata.message = asyncResult.error.message;
      }
    }
  );
}

function insertSvg(fileName, type) {
  return new Promise(function(resolve, reject) {
    try {
      getbase64froms3bucketsvg(fileName, type, function(data) {
        var base64 = data.Body.toString("base64");
        var svgxml = atob(base64);
        insertSVGFromXml(svgxml);
        resolve(true);
      });
    } catch (error) {
      resolve(false);
    }
  });
}

function getbase64froms3bucketsvg(filename, type, callback) {
  AWS.config.update({
    accessKeyId: "AKIARVHHZRJPJ244U4EZ",
    secretAccessKey: "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"
  });
  AWS.config.region = "ap-south-1";

  var bucket = new AWS.S3({ params: { Bucket: "inkppt-beta-frontend" } });

  bucket.getObject({ Key: "Images/Assets/" + type + "/" + filename }, function(
    error,
    data
  ) {
    callback(data);
  });
}

function insertSVGFromXml(image) {
  try {
    // Call Office.js to insert the image into the document.
    const svgImage =
      '<svg><rect x="0" y="0" height="50" width="50" style="stroke:#ff0000; fill:#0000ff" /></svg>';
    const svgIsSupported = Office.context.requirements.isSetSupported(
      "ImageCoercion",
      1.2
    );
    if (svgIsSupported) {
      Office.context.document.setSelectedDataAsync(
        image,
        {
          coercionType: Office.CoercionType.XmlSvg
        },
        function(asyncResult) {
          if (asyncResult.status === Office.AsyncResultStatus.Failed) {
            icondata.message = asyncResult.error.message;
          }
        }
      );
    }
  } catch (error) {
    console.log(error);
  }
}
